import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, Typography } from '@material-ui/core';
import {
  CARRIER_TOOLIP_STATE,
  CARRIER_TOOLTIP,
  FC_PLATFORM,
  THANK_YOU,
  VERIFYING_PROCESS
} from 'constants/constant';
import closeicon from '../../assets/images/close.png';
import './req-appoinment-modal.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { carrierStoreThankYouModalClose, setAuthInfo } from 'redux/actions/auth';
import moment from 'moment';

const ThankYou = props => {
  const [openThankYou, setOpenThankYou] = useState(false);

  const setSignupDate= () => {
    if (
      !localStorage.getItem('modalDeferredSignupTimestamp') ||
      localStorage.getItem('modalDeferredSignupTimestamp') === 'null' ||
      localStorage.getItem('modalDeferredSignupTimestamp') === null
    ) {
      localStorage.setItem(
        'modalDeferredSignupTimestamp',
        moment().format('DD/MM/YYYY')
      );
    }
  };

  const closeThankYOu = () => {
    setOpenThankYou(false);
    setSignupDate();
    props.carrierStoreThankYouModalClose(true);
    props.setAuthInfo({
      enableMenuItem: true,
    });
    localStorage.setItem('enableMenuItem', true);
    localStorage.setItem(CARRIER_TOOLTIP, CARRIER_TOOLIP_STATE.ADDED);
  };

  useEffect(() => {
    let isEnabledMenuBar = JSON.parse(localStorage.getItem("enableMenuItem")) || false;
    if (!!props?.carrierStoreThankYouModal && !isEnabledMenuBar) {
      setOpenThankYou(props.carrierStoreThankYouModal);
    }
  }, [props.carrierStoreThankYouModal]);

  return (
    <Dialog
      className="req-appoinment-modal"
      fullWidth={true}
      maxWidth="xs"
      aria-labelledby="max-width-dialog-title"
      open={openThankYou}
      onBackdropClick={closeThankYOu}
    >
      <img
        src={closeicon}
        alt="close"
        className="close-icon"
        onClick={closeThankYOu}
      />
      <DialogContent className="req-appoinment-modal-content">
        <div className="d-flex justify-content-center">
          <Typography className="heading-label">{THANK_YOU}</Typography>
        </div>
        <div className="d-flex justify-content-center">
          <Typography className="verify-heading-label">
            {VERIFYING_PROCESS}
          </Typography>
        </div>
        <div className="d-flex justify-content-center">
          <Typography className="third-heading-label">{FC_PLATFORM}</Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = ({ auth }) => {
  const { carrierStoreThankYouModal } = auth;
  return {
    carrierStoreThankYouModal,
  };
};

ThankYou.propTypes = {
  carrierStoreThankYouModal: PropTypes.bool,
};

ThankYou.defaultProps = {
  carrierStoreThankYouModal: PropTypes.bool,
};

export default connect(mapStateToProps, { carrierStoreThankYouModalClose, setAuthInfo })(
  ThankYou
);
