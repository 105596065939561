import { Button } from '@material-ui/core';
import clsx from 'clsx';
import CarrierNavItem from 'components/carrierNavItem/carrierNavItem';
import { PillLabel } from 'components/pill-label';
import config from 'config';
import {
  ADMIN,
  AGENCY_PROFILE_MENU,
  ALTERNATE_SUB_PRODUCER,
  FLOOD_RATER,
  MODULE,
  MY_ACCOUNT,
  PRINCIPAL_ACCESS_ROLE,
  RESOURCE_CENTER_LABEL,
  SUB_PRODUCER_KEY,
  TEND_HOME,
  TERMINATED,
} from 'constants/constant';
import { PORTAL } from 'constants/heap-events';
import { getModules } from 'constants/modules';
import {
  AGENT_TOOLS_MODULE,
  CASH_CONNECT_MODULE,
  HELP_CENTER_MODULE,
  MODULES_WITH_NEW_LABEL,
  NEW_APP_MODULES,
} from 'containers/side-nav/side-nav.constants';
import { compact, isEmpty, isNull, isUndefined, map } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setCarrierTooltip } from 'redux/actions/auth';
import { fetchFastStartPolicies } from 'redux/actions/dashboard';
import CustomScrollbars from 'util/custom-scrollbars';
import {
  convertArrayToObject,
  getAuthDetails,
  getEncodedUrl,
  getModulePermission,
  isCarrierStoreSubProducer,
  isObject,
} from 'util/extra';
import './side-nav-content.scss';
import { navigateToNewAppRoute } from 'util/navigate-to-new-app-route';
import { useFlagship } from '@flagship.io/react-sdk';
import { FEATURE_FLAGS } from '../../constants/feature-flags';

class SidenavContent extends Component {
  constructor() {
    super();
    this.state = {
      selectedModule: 'dashboard',
      showCarrierTooltip: true,
    };
  }

  UNSAFE_componentWillMount() {
    this.setState({
      selectedModule: this.getSelectedModuleFromString(
        this.props?.location?.pathname
      ),
    });
  }

  componentDidMount() {
    const { history } = this.props;
    const pathname = `${history.location.pathname}`; // get current path
    this.addListenerToMenu();

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element

    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul

      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {
      // handle error
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element

    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul

      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {
      // handle error
    }

    // fix for selected nav li element.
    const openLiList = document.querySelectorAll('.open');

    if (openLiList.length > 1) {
      map(openLiList, (li) => {
        if (!isNull(li.querySelector('.active'))) {
          li.classList.remove('open');
        }
      });
    }
  }

  componentDidUpdate() {
    this.addListenerToMenu();
  }

  getSelectedModuleFromString(value) {
    return value.split('/')[3];
  }

  selectNavItem = (module) => {
    const selectedModule = this.getSelectedModuleFromString(module);
    this.setState({ selectedModule });
  };

  getSelectedClass = (link) => {
    let value = this.state.selectedModule;
    if (isUndefined(value)) {
      value = this.getSelectedModuleFromString(this.props?.location?.pathname);
    }
    const isSelectedTab =
      window.location.href.includes('fast-start') ||
      window.location.href.includes('carrier-store') ||
      window.location.href.includes('dashboard') ||
      window.location.href.includes('q2-incentive');
    return (
      (value === this.getSelectedModuleFromString(link) || isSelectedTab) &&
      'selectedNav'
    );
  };

  addListenerToMenu() {
    const { isImpersonate } = this.props;
    const that = this;
    const menuLi = document.getElementsByClassName('menu');

    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        if (event.target.className !== 'sub-menu-approval') {
          const parentLiEle = that.closest(this, 'li');

          if (menuLi[i].classList.contains('menu') && parentLiEle !== null) {
            event.stopPropagation();

            if (menuLi[i].classList.contains('open')) {
              menuLi[i].classList.remove('open', 'active');
            } else {
              menuLi[i].classList.add('open', 'active');
            }
          } else {
            for (let j = 0; j < menuLi.length; j++) {
              const parentLi = that.closest(this, 'li');

              if (
                menuLi[j] !== this &&
                (parentLi === null || !parentLi.classList.contains('open'))
              ) {
                menuLi[j].classList.remove('open');
              } else {
                if (menuLi[j].classList.contains('open')) {
                  menuLi[j].classList.remove('open');
                } else if (menuLi[j].innerText !== RESOURCE_CENTER_LABEL) {
                  menuLi[j].classList.add('open');
                }
              }
            }
          }
        }
      };
      if (
        !isImpersonate &&
        !!window.resetActiveMenuItem &&
        window.resetActiveMenuItem
      ) {
        menuLi[i].classList.remove('open', 'active');
      }
    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        'matches',
        'webkitMatchesSelector',
        'mozMatchesSelector',
        'msMatchesSelector',
        'oMatchesSelector',
      ].some(function (fn) {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log('closest error', e);
    }
    return null;
  }

  sortModule = (obj) => {
    const moduleArray = Object.keys(obj).map((module) => {
      let subModuleArray = null;
      if (isObject(obj[module]) && !isEmpty(obj[module])) {
        const subModuleSort = Object.entries(obj[module]).sort((a, b) => {
          return a[1].display_order - b[1].display_order;
        });
        subModuleArray = Object.assign(
          { module },
          Object.fromEntries(subModuleSort)
        );
      }
      return subModuleArray;
    });
    const moduleSortedArray = moduleArray.sort((a, b) => {
      return a.display_order - b.display_order;
    });
    return convertArrayToObject(moduleSortedArray, MODULE);
  };

  includeFloodRater(modules) {
    const modulesWithFloodRater = {
      ...modules,
      'FLOOD-RATER': {
        delete: false,
        display_order: 8,
        has_submodule: false,
        read: true,
        write: true,
      },
    };
    const { floodRaterCarrier, userRole, isImpersonate } =
      !!this.props && this.props;

    if (!!!isImpersonate && userRole === ADMIN) return modules;

    if (!!!floodRaterCarrier) {
      const floodRaterCarrierRecord =
        localStorage.getItem('floodRaterCarrier') || null;
      const data = JSON.parse(floodRaterCarrierRecord) || '';

      if (data && data.floodRaterCarrier) {
        return modulesWithFloodRater;
      }

      return modules;
    }

    return !!floodRaterCarrier ? modulesWithFloodRater : modules;
  }

  includeTendHome(modules) {
    const modulesWithTendHome = {
      ...modules,
      'TEND-HOME': {
        delete: false,
        display_order: 13,
        has_submodule: false,
        read: true,
        write: true,
      },
    };

    const { tendCarrier, userRole, isImpersonate } = !!this.props && this.props;

    if (!!!isImpersonate && userRole === ADMIN) return modules;
    if (!!!tendCarrier) {
      const tendCarrierRecord = localStorage.getItem('tendCarrier') || null;
      const data = JSON.parse(tendCarrierRecord) || '';

      if (data && data.tendCarrier) {
        return modulesWithTendHome;
      }

      return modules;
    }

    return !!tendCarrier ? modulesWithTendHome : modules;
  }

  getMyAccountURL = (url) => {
    const { producer_id, producer_code } = this.props;
    if (
      !!producer_id &&
      !isEmpty(producer_id.toString()) &&
      !isEmpty(producer_code)
    ) {
      return `${url}`;
    }

    return '';
  };

  getAgencyProfile = (url) => {
    const { fkParentProducerId, producer_code } = this.props;

    if (
      !!fkParentProducerId &&
      !isEmpty(fkParentProducerId.toString()) &&
      !isEmpty(producer_code)
    ) {
      const parentProducerCode = producer_code.includes('-')
        ? producer_code.split('-')[0]
        : producer_code;

      return `${url}/${getEncodedUrl(fkParentProducerId, parentProducerCode)}`;
    }

    return '';
  };

  hideAppointmentConsole(modules) {
    const modulesWithAppointmentConsole = {
      ...modules,
      'CARRIER-APPOINTMENTS': {
        has_submodule: false,
        display_order: 11,
        read: false,
        write: false,
        delete: false,
      },
    };

    const { userRole, isImpersonate } = this.props;

    if (!!!isImpersonate && userRole === ADMIN) return modules;

    return modulesWithAppointmentConsole;
  }

  includeAppetiteGuide(modules) {
    const modulesWithAppetiteGuide = {
      ...modules,
      'APPETITE-GUIDE': {
        delete: false,
        display_order: 6,
        has_submodule: false,
        read: true,
        write: true,
      },
    };
    const { userRole, isImpersonate } = this.props;
    if (!isImpersonate && userRole === ADMIN) return modules;

    const flag = this.props.flagship.getFlag(
      FEATURE_FLAGS.APPETITE_GUIDE_ENABLE,
      true
    );

    if (!flag.getValue()) {
      return modules;
    }

    return modulesWithAppetiteGuide;
  }

  includeCashConnect(modules) {
    const modulesWithCashConnect = {
      ...modules,
      [CASH_CONNECT_MODULE]: {
        delete: false,
        display_order: 11,
        has_submodule: false,
        read: true,
        write: true,
      },
    };
    const { userRole, isImpersonate } = this.props;

    if (!isImpersonate && userRole === ADMIN) return modules;

    return modulesWithCashConnect;
  }

  includeMyAccountForPrincipalAccess(modules) {
    const modulesWithMyAccount = {
      ...modules,
      [MY_ACCOUNT]: {
        ...modules[MY_ACCOUNT],
        display_order: 16,
      },
    };
    const { userRole, isImpersonate } = this.props;

    if (!isImpersonate && userRole === ADMIN) return modules;

    return modulesWithMyAccount;
  }

  includeAgentTools(modules) {
    const modulesWithAgentTools = {
      ...modules,
      [AGENT_TOOLS_MODULE]: {
        delete: false,
        display_order: 14,
        has_submodule: false,
        read: true,
        write: true,
      },
    };
    const { userRole, isImpersonate } = this.props;

    if (!isImpersonate && userRole === ADMIN) return modules;

    return modulesWithAgentTools;
  }

  includeHelpCenter(modules) {
    const modulesWithHelpCenter = {
      ...modules,
      [HELP_CENTER_MODULE]: {
        delete: false,
        display_order: 15,
        has_submodule: false,
        read: true,
        write: true,
      },
    };
    const { userRole, isImpersonate } = this.props;

    if (!isImpersonate && userRole === ADMIN) return modules;

    return modulesWithHelpCenter;
  }

  getSideModule(module, moduleData, navLinkTo, withLabel = true) {
    // Can't use internal routing for modules implemented in new app
    if (NEW_APP_MODULES.includes(module)) {
      return (
        <a
          href={navLinkTo}
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingInlineEnd: '15px',
          }}
          onClick={(e) => {
            e.preventDefault();
            navigateToNewAppRoute(navLinkTo);
            return;
          }}
        >
          {moduleData?.icon}
          <span className="nav-text">{moduleData?.title}</span>
          {MODULES_WITH_NEW_LABEL.includes(module) && withLabel && (
            <PillLabel styles={{ marginLeft: 'auto' }}>New</PillLabel>
          )}
        </a>
      );
    }

    switch (module) {
      case HELP_CENTER_MODULE:
        return (
          <a
            href={moduleData?.url}
            target="__blank"
            onClick={() => window.heap.track(PORTAL.PORTAL_HELP_CENTER_CLICK)}
          >
            {moduleData?.icon}
            <span className="nav-text">{moduleData?.title}</span>
          </a>
        );
      default:
        return (
          <NavLink
            to={navLinkTo}
            activeClassName={this.getSelectedClass(navLinkTo)}
            onClick={() => {
              this.selectNavItem(navLinkTo);
            }}
          >
            {moduleData?.icon}
            <span className="nav-text">{moduleData?.title}</span>
          </NavLink>
        );
    }
  }

  includeAccessForTerminatedStatus(modules) {
    const modulesForTerminatedStatus = {
      ...modules,
      ['CASH-CONNECT']: {
        ...modules['CASH-CONNECT'],
        read: false,
        write: false,
      },
      ['APPETITE-GUIDE']: {
        ...modules['APPETITE-GUIDE'],
        read: false,
        write: false,
      },
      ['CARRIER-APPOINTMENTS']: {
        ...modules['CARRIER-APPOINTMENTS'],
        read: false,
        write: false,
      },
      ['AGENT-TOOLS']: {
        ...modules['AGENT-TOOLS'],
        read: false,
        write: false,
      },
      ['HELP-CENTER']: {
        ...modules['HELP-CENTER'],
        read: false,
        write: false,
      },
    };
    const { userRole, isImpersonate } = this.props;
    if (!isImpersonate && userRole === ADMIN) return modules;

    return modulesForTerminatedStatus;
  }
  render() {
    const { props } = this;
    const {
      userRole,
      modulePermissionsData,
      isImpersonate,
      isWholesaler,
      signupFlow,
      role,
      userStatus,
    } = props;

    const producerStatusAggregate =
      localStorage.getItem('producerStatusAggregate') || null;
    const filterCounts = JSON.parse(producerStatusAggregate);
    const showCarrierStore = isCarrierStoreSubProducer(isWholesaler);
    const isSubProducer = [ALTERNATE_SUB_PRODUCER, SUB_PRODUCER_KEY].includes(
      signupFlow
    );

    const isPrincipalAccessProducer = role === PRINCIPAL_ACCESS_ROLE;
    let updatedModules =
      (isSubProducer || !isSubProducer) && showCarrierStore
        ? modulePermissionsData
        : this.hideAppointmentConsole(modulePermissionsData);

    updatedModules = this.includeFloodRater(updatedModules);
    updatedModules = this.includeTendHome(updatedModules);

    if ((!isSubProducer && !isWholesaler) || isPrincipalAccessProducer) {
      updatedModules = this.includeCashConnect(updatedModules);
    }

    updatedModules = this.includeAppetiteGuide(updatedModules);
    updatedModules = this.includeHelpCenter(updatedModules);
    updatedModules = this.includeAgentTools(updatedModules);
    if (isPrincipalAccessProducer) {
      updatedModules = this.includeMyAccountForPrincipalAccess(updatedModules);
    }
    updatedModules =
      userStatus?.toLowerCase() === TERMINATED
        ? this.includeAccessForTerminatedStatus(updatedModules)
        : updatedModules;
    const sortedObject = !!updatedModules && this.sortModule(updatedModules);

    return (
      <CustomScrollbars className="scrollbar">
        <ul className="nav-menu menubar">
          {!!sortedObject &&
            Object.keys(sortedObject).map((module, index) => {
              let showMenu = [];
              let hasSubMenu = false;

              if (sortedObject[module].has_submodule) {
                showMenu = Object.keys(sortedObject[module]).map(
                  (subModule) => {
                    if (
                      !['module', 'has_submodule', 'display_order'].includes(
                        subModule
                      )
                    ) {
                      const detail = getModules(
                        userRole,
                        subModule,
                        isImpersonate
                      );
                      hasSubMenu = !!detail;

                      return sortedObject[module][subModule].read;
                    }

                    return undefined;
                  }
                );
              }
              showMenu = compact(showMenu);
              const moduleData = getModules(
                userRole,
                module,
                isImpersonate,
                isSubProducer
              );
              const navLinkTo =
                moduleData && module === MY_ACCOUNT
                  ? this.getMyAccountURL(moduleData.url)
                  : module !== AGENCY_PROFILE_MENU
                  ? moduleData?.url
                  : this.getAgencyProfile(moduleData.url);
              return (
                <React.Fragment key={index}>
                  {hasSubMenu && showMenu.includes(true) ? (
                    <li className="menu collapse-box">
                      <Button disableRipple>
                        {moduleData?.icon}
                        <span className="nav-text">{moduleData?.title}</span>
                      </Button>
                      {Object.keys(sortedObject[module]).map((subModule, i) => {
                        const subModuleData = getModules(
                          userRole,
                          subModule,
                          isImpersonate
                        );

                        if (subModuleData?.hide) {
                          return null;
                        }

                        const hasSubModule = !!subModuleData;
                        const { countType, title, url, isNestedItem } =
                          subModuleData || {};
                        const moduleProps = sortedObject[module][subModule];

                        const itemCount =
                          (filterCounts && filterCounts[countType]) || 0;

                        return (
                          <React.Fragment key={i}>
                            {!!moduleProps.read && hasSubModule && (
                              <ul className="sub-menu" id={subModule}>
                                <li
                                  className={clsx({
                                    'sub-menu-approval':
                                      moduleProps.display_order === 7,
                                  })}
                                >
                                  <NavLink
                                    to={hasSubModule && url}
                                    activeClassName={this.getSelectedClass(url)}
                                    onClick={() => {
                                      this.selectNavItem(hasSubModule && url);
                                    }}
                                  >
                                    <span
                                      className={clsx([
                                        'nav-text',
                                        isNestedItem && 'thirdLevel',
                                      ])}
                                    >
                                      {title}
                                      {(countType || countType === 0) && (
                                        <>
                                          &nbsp;
                                          <span>({itemCount})</span>
                                        </>
                                      )}
                                    </span>
                                  </NavLink>
                                </li>
                              </ul>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </li>
                  ) : (
                    <li className="menu no-arrow" id={module}>
                      {(module === FLOOD_RATER || module === TEND_HOME) && (
                        <CarrierNavItem
                          role={userRole}
                          carrier_id={
                            module === FLOOD_RATER
                              ? props.floodRaterCarrier.carrier_id
                              : props.tendCarrier.carrier_id
                          }
                          producer_id={
                            module === FLOOD_RATER
                              ? props.floodRaterCarrier.producer_id
                              : props.tendCarrier.producer_id
                          }
                          module={module}
                          impersonate={isImpersonate}
                        />
                      )}
                      {module !== FLOOD_RATER &&
                        module !== TEND_HOME &&
                        !!moduleData &&
                        (sortedObject[module].read ||
                          showMenu.includes(true)) &&
                        (navLinkTo ? (
                          this.getSideModule(
                            module,
                            moduleData,
                            navLinkTo,
                            !this.props.isNavCollapsed
                          )
                        ) : (
                          <a href={config.RESOURCE_CENTER_URL} target="__blank">
                            {moduleData?.icon}
                            <span className="nav-text">
                              {moduleData?.title}
                            </span>
                          </a>
                        ))}
                    </li>
                  )}
                </React.Fragment>
              );
            })}
        </ul>
      </CustomScrollbars>
    );
  }
}

const mapStateToProps = ({
  auth,
  agentDetails,
  impersonate,
  modulePermissions,
  dashboard,
  myAccount,
  q2Incentive,
  incentive,
  producerSettings,
}) => {
  const { filterCounts } = agentDetails;
  const { producer, permissions, isImpersonate } = impersonate;
  const {
    fkParentProducerId,
    producer_code,
    producer_id,
    userRole,
    shouldShowFastStart,
    isWholesaler,
    signupFlow,
    role,
    userStatus,
  } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const { q2IncentiveDaysRemaining } = q2Incentive;
  const { q3DaysRemaining } = incentive;
  const { isCanopyConnectEnabled, isLeadFeedEnabled, isAgentToolsEnabled } =
    producerSettings;

  const {
    floodRaterCarrier,
    tendCarrier,
    appointments,
    carrierTooltip,
    writesUnderAgencyLicense,
    daysRemaining,
    isInitialModalViewed,
    isSecondaryModalViewed,
    isTertiaryModalViewed,
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
    showSSNEditPrompt,
  } = auth;
  const modulePermissionsData = getModulePermission(
    modulePermissions.modulePermissionsData,
    permissions,
    isImpersonate
  );
  const {
    appointedCarriers,
    carrierStoreModal,
    consentModal,
    bankInfoModal,
    fastStartModal,
    restrictionModal,
    w9InforModal,
    carrierLoader,
  } = dashboard;
  const { loader } = myAccount;
  return {
    fkParentProducerId,
    producer_code,
    producer_id,
    userRole,
    isWholesaler,
    filterCounts,
    signupFlow,
    modulePermissionsData,
    isImpersonate,
    floodRaterCarrier,
    tendCarrier,
    appointments,
    shouldShowFastStart,
    carrierTooltip,
    carrierStoreModal,
    consentModal,
    writesUnderAgencyLicense,
    daysRemaining,
    isInitialModalViewed,
    isSecondaryModalViewed,
    isTertiaryModalViewed,
    bankInfoModal,
    fastStartModal,
    restrictionModal,
    w9InforModal,
    carrierLoader,
    loader,
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
    showSSNEditPrompt,
    q2IncentiveDaysRemaining,
    q3DaysRemaining,
    appointedCarriers,
    isCanopyConnectEnabled,
    isLeadFeedEnabled,
    isAgentToolsEnabled,
    role,
    userStatus,
  };
};

function withFlagship(WrappedComponent) {
  return function WithFlagship(props) {
    const flagship = useFlagship();
    return <WrappedComponent {...props} flagship={flagship} />;
  };
}

export default connect(mapStateToProps, {
  setCarrierTooltip,
  fetchFastStartPolicies,
})(withFlagship(SidenavContent));
