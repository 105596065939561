import React from 'react';
import config from 'config';
import { isEmpty } from 'lodash';
import { usePlacesWidget } from 'react-google-autocomplete';
import { address_validation } from 'util/sign-up-process';
import { useAutocompletePosition } from './search-location-input.hooks';
import './search-location-input.scss';

const SearchLocationInput = ({
  error,
  setValue,
  valueKey,
  onChangeCallBack,
  mappingKeys,
  handleChange,
  ...rest
}) => {
  const { ref } = usePlacesWidget({
    apiKey: config.GOOGLE_API_KEY,
    onPlaceSelected: (addressObject) => {
      let streetNumber = '';
      let route = '';
      let city = '';
      let state = '';
      let zipCode = '';

      addressObject?.address_components?.map((el) => {
        if (el.types.some((type) => type === 'street_number'))
          streetNumber = el.long_name;
        if (el.types.some((type) => type === 'route')) route = el.long_name;
        if (el.types.some((type) => type === 'locality')) city = el.long_name;
        if (el.types.some((type) => type === 'administrative_area_level_1'))
          state = el.long_name;
        if (el.types.some((type) => type === 'postal_code'))
          zipCode = el.long_name;
      });

      const address = `${streetNumber} ${route}`;
      setValue(valueKey, address);
      setValue(mappingKeys.city, city);
      setValue(mappingKeys.state, state);
      setValue(mappingKeys.zip, zipCode);
    },
    options: { types: ['address'], componentRestrictions: { country: 'us' } },
  });

  useAutocompletePosition();

  const onChangeHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      !isEmpty(event.target.value) &&
      !address_validation.test(event.target.value)
    )
      return;

    const value =
      event.target.value.trim().length === 1
        ? event.target.value.trim()
        : event.target.value;
    const [newValue, change] = handleChange?.(value) || [
      event.target.value,
      true,
    ];

    if (change) {
      setValue(valueKey, newValue, onChangeCallBack);
    }
  };

  return (
    <div className="search-location-input">
      <input ref={ref} {...rest} onChange={onChangeHandler} placeholder="" />
      <div className="error-msg">{error || ''}</div>
    </div>
  );
};

export default SearchLocationInput;
