// 🚨 Until those configs are migrated into a separate package, or dual app setup is removed
// 🚨 part of auth0-related code is duplicated between old and new FE repositories.
// 🚨 Any changes to this file need to be reflected in the new FE.

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import config from 'config';
import moment from 'moment';

import {
  SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM,
  SIGNOUT_KEEP_REDIRECT_COOKIE,
} from 'constants/constant';
import {
  REAUTHENTICATE_ROUTE,
  SIGNIN_ROUTE,
  SIGNOUT_ROUTE,
} from 'constants/routes';
import { setRedirectUrlCookie } from 'util/redirect-url';

import { getIsPasswordlessRememberMe } from './auth0.utils';
import { getIsAutnenticatedForGradualRollout } from './get-is-authenticated-for-gradual-rollout';

const INACTIVITY_CHECK_INTERVAL_SECONDS = 20;

const LAST_ACTIVITY_TIMESTAMP_LS_KEY = 'lastActivityMsTimsamp';

const IGNORED_ENDPOINTS = ['api/v1/producer/notifications'];

export const isValidAuthActivity = (url) => {
  return !IGNORED_ENDPOINTS.some((ignored) => url.includes(ignored));
};

export const storeLastActivityTimestamp = () => {
  localStorage.setItem(LAST_ACTIVITY_TIMESTAMP_LS_KEY, Date.now().toString());
};

export const getLastActivityTimestamp = () => {
  return Number(localStorage.getItem(LAST_ACTIVITY_TIMESTAMP_LS_KEY));
};

export const getInactivityExpirationSeconds = () => {
  const expiration = getIsPasswordlessRememberMe()
    ? config.SESSION_INACTIVITY_EXPIRY_SEC_REMEMBER_ME
    : config.SESSION_INACTIVITY_EXPIRY_SEC;
  if (typeof expiration !== 'number' || !expiration) {
    return 0;
  }
  return expiration;
};

export const checkIsInactive = () => {
  const expirationSeconds = getInactivityExpirationSeconds();
  if (!expirationSeconds) {
    return false;
  }

  const lastActivityTs = getLastActivityTimestamp();
  if (!lastActivityTs) {
    return false;
  }

  return Date.now() - lastActivityTs > expirationSeconds * 1000;
};

const logInactivitySignout = () => {
  const now = Date.now();
  const lastActivityTs = getLastActivityTimestamp();

  console.log('AUTH0 inactivity signout legacy', {
    lastActivity: moment(lastActivityTs).format('YYYY-MM-DD HH:mm:ss'),
    now: moment(now).format('YYYY-MM-DD HH:mm:ss'),
    diff: moment(now - lastActivityTs).format('HH:mm:ss'),
    rememberMe: getIsPasswordlessRememberMe(),
  });
};

export const useSignoutOnInactivityEffect = () => {
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      const isAuthenticated = getIsAutnenticatedForGradualRollout();
      const isIgnoredRoute = [
        SIGNIN_ROUTE,
        REAUTHENTICATE_ROUTE,
        'post-signup',
      ].some((route) => window.location.pathname.includes(route));

      if (!isAuthenticated || isIgnoredRoute) {
        return;
      }

      const isInactive = checkIsInactive();

      if (isInactive) {
        logInactivitySignout();
        setRedirectUrlCookie(window.location.pathname, 'producer');
        history.push(
          `${SIGNOUT_ROUTE}?${SIGNOUT_IS_INACTIVITY_REAUTHENTICATE_SEARCH_PARAM}=true&${SIGNOUT_KEEP_REDIRECT_COOKIE}=true`
        );
      }
    }, INACTIVITY_CHECK_INTERVAL_SECONDS * 1000);

    return () => clearInterval(interval);
  }, []);
};
