import { all, fork, put, select, take, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import * as dashboardActions from 'constants/action-types';
import {
  carrierCredentialLoader,
  errorHandler,
  fetchQuoteConnectorSettings,
  setAccessQuotingPageLoader,
  setAddedFastStartDetails,
  setAddFastStartPolicyLoader,
  setCarrierPortalErrorDialog,
  setCarrierPortalLoader,
  setCarrierPortalsDetails,
  setCarrierProducerAppointmentId,
  setCarrierUrl,
  setCarrierUrlLoader,
  setCurrentCarrier,
  setFastStartPolicyList,
  setFastStartPolicyListLoader,
  setIsFastStartAdded,
  setIsFastStartPolicyAddedd,
  setLaunchUrl,
  setPolicyPremiumDetailsLoader,
  setProducerQuoteOptionsList,
  setProducerQuotesOptionsLoader,
  setQuoteNotificationIndicatorLoader,
  setQuotesList,
  setQuotesListLoader,
  setUpcomingRenewalsLoader,
  storePolicyPremiumDetails,
  storeUpcomingRenewals,
} from '../actions';
import { getAuthDetails, getCountForPoliciesDetails } from 'util/extra';
import { filter, flatMap, get, isObject, merge } from 'lodash';
import { ENTITY_QUOTE } from 'constants/constant';
import * as rolePermissionActions from '../../constants/action-types';
import { displaySuccessMessage } from 'util/user-feedback';
import { getImpersonateBFFRequestConfig, isImpersonated } from './impersonate';
import config from 'config';

function* fetchPolicyPremiumDetails(payload) {
  yield put(setPolicyPremiumDetailsLoader(true));
  try {
    const {
      auth: { producer_id },
    } = yield select();
    let selectedProducerId = get(payload, 'producer_id', producer_id);

    const details = yield axios.get(
      `/api/v1/producer/agent-policy-premium/${selectedProducerId}`
    );

    if (details.data) {
      const {
        carriers,
        policies_count_by_carrier,
        premium_report,
        lastPublishedCommission,
      } = details.data;

      // get policies sold details for each valid fiscal month.
      const policiesSoldDetails = getCountForPoliciesDetails(
        policies_count_by_carrier,
        'policy', // for month policies total
        'totalPoliciesSold', // for aggregate month policies total
        'policyCounts'
      );

      // get premium bound details for each valid fiscal month.
      const premiumBoundDetails = getCountForPoliciesDetails(
        premium_report,
        'amount', // for month premium total
        'totalAmount', // for aggregate total premium
        'amountCounts'
      );

      let mappedAxisData = [];

      mappedAxisData = merge({}, premiumBoundDetails, policiesSoldDetails);
      mappedAxisData = flatMap(mappedAxisData);
      mappedAxisData = filter(mappedAxisData, (value) => {
        if (isObject(value) && value.name) {
          !get(value, 'amount') && (value.amount = 0);
          !get(value, 'policy') && (value.policy = 0);
          return value;
        }
      });

      yield put(
        storePolicyPremiumDetails({
          policies_count_by_carrier: policiesSoldDetails,
          premium_report: premiumBoundDetails,
          axisDetail: mappedAxisData,
          agentCarrierPolicyColor: carriers,
          lastPublishedCommission,
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setPolicyPremiumDetailsLoader(false));
}

export function* fetchPolicyPremiumDetailsWatcher() {
  yield takeEvery(
    dashboardActions.FETCH_POLICY_PREMIUM_DETAILS,
    fetchPolicyPremiumDetails
  );
}

function* fetchUpcomingRenewalDetails({ payload }) {
  try {
    yield put(setUpcomingRenewalsLoader(true));
    const details = yield axios.post('/api/v1/producer/upcoming-renewals', {
      producer_id: payload.producerId,
    });
    if (details.data) {
      yield put(storeUpcomingRenewals(get(details, 'data.policies', [])));
    }
  } catch (error) {
    yield put(storeUpcomingRenewals([]));
  }
  yield put(setUpcomingRenewalsLoader(false));
}

export function* fetchUpcomingRenewalDetailsWatcher() {
  yield takeEvery(
    dashboardActions.FETCH_UPCOMING_RENEWALS,
    fetchUpcomingRenewalDetails
  );
}

function* fetchCarrierPortalsDetails() {
  try {
    yield put(setCarrierPortalLoader(true));
    const store = yield select();
    const { auth, impersonate } = store;
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });
    const carrierPortalDetails = yield axios.get(
      `/api/v1/producer/carrier-portals/${producer_id}`
    );

    if (
      carrierPortalDetails.data &&
      get(carrierPortalDetails, 'data.success', false)
    ) {
      yield put(
        setCarrierPortalsDetails(get(carrierPortalDetails, 'data', ''))
      );
    }
  } catch (error) {
    yield put(setCarrierPortalsDetails([]));
    yield put(errorHandler(error));
  }
  yield put(setCarrierPortalLoader(false));
}

export function* fetchCarrierPortalsDetailsWatcher() {
  yield takeEvery(
    dashboardActions.FETCH_CARRIER_PORTALS_DETAILS,
    fetchCarrierPortalsDetails
  );
}

function* getCarrierLink({ payload }) {
  try {
    yield put(setCarrierUrlLoader(true));
    const carrierPortalUrl = yield axios.post(
      'api/v1/carrier/get-carrier-portal-link',
      {
        carrier_id: payload.carrier_id,
        producer_id: payload.producer_id,
      }
    );
    if (
      carrierPortalUrl.data &&
      get(carrierPortalUrl, 'data.carrier_link', false)
    ) {
      let url = carrierPortalUrl.data.carrier_link.match(/^https?:/)
        ? carrierPortalUrl.data.carrier_link
        : '//' + carrierPortalUrl.data.carrier_link;
      if (
        !!payload?.availableForCrossSellExperience &&
        payload?.availableForCrossSellExperience
      ) {
        yield put(setCarrierUrl(url));
        yield put(setCurrentCarrier(payload.carrier));
      } else {
        window.open(url, '_blank');
      }
    }
    yield put(setCarrierUrlLoader(false));
  } catch (error) {
    if (error?.response?.status === 412 && error?.response?.data?.showDialog) {
      yield put(setCarrierPortalErrorDialog(error?.response?.data));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setCarrierUrlLoader(false));
}

export function* getCarrierLinkWatcher() {
  yield takeEvery(dashboardActions.GET_CARRIER_LINK, getCarrierLink);
}

export function* fetchQuotesListWatcher() {
  yield takeEvery(dashboardActions.FETCH_QUOTES_LIST, fetchQuotesList);
}

function* fetchQuotesList({ payload: { producerId } }) {
  yield put(setQuotesListLoader(true));

  const isImpersonate = yield isImpersonated();
  let isNewQCEnabled = yield select(
    (state) => state.quoteConnector.isNewQuoteConnectorEnabled
  );

  if (isImpersonate || isNewQCEnabled === null) {
    yield put(fetchQuoteConnectorSettings());
    yield take(dashboardActions.FETCH_QUOTE_CONNECTOR_SETTINGS_RESULT);
    isNewQCEnabled = yield select(
      (state) => state.quoteConnector.isNewQuoteConnectorEnabled
    );
  }

  if (isNewQCEnabled) {
    yield fetchMergedQuotesList({ payload: { producerId } });
  } else {
    yield fetchOldQuotesList({ payload: { producerId } });
  }

  yield put(setQuotesListLoader(false));
}

function* fetchMergedQuotesList({ payload: { producerId } }) {
  const [oldQuotesWithMetadata, newQuotes] = yield all([
    fetchOldQuotesMappedWithMetadata({ payload: { producerId } }),
    fetchNewQuotesMapped(),
  ]);
  yield put(
    setQuotesList({
      quotesList: [...oldQuotesWithMetadata.quotesList, ...newQuotes],
      showQuotes: oldQuotesWithMetadata.showQuotes,
      availableLOBs: oldQuotesWithMetadata.availableLOBs,
      availableCarriers: oldQuotesWithMetadata.availableCarriers,
    })
  );
}

function* fetchOldQuotesMappedWithMetadata({ payload: { producerId } }) {
  try {
    const quoteList = yield axios.get(`api/v1/producer/quotes/${producerId}`);
    if (!quoteList.data.success || !quoteList?.data?.quotes) {
      return [];
    }
    const { availableLOBs, quotes, showQuotes, availableCarriers } =
      quoteList.data;

    return {
      quotesList: quotes,
      showQuotes: showQuotes,
      availableLOBs: availableLOBs ?? [],
      availableCarriers: availableCarriers ?? [],
    };
  } catch (error) {
    yield put(errorHandler(error));
    return [];
  }
}

function* fetchNewQuotesMapped() {
  try {
    const requestConfig = yield getImpersonateBFFRequestConfig();
    const newQuotesResponse = yield axios.get(
      `${config.BFF_URL}/api/v1/quote-connector/list`,
      requestConfig
    );
    return newQuotesResponse.data.items.map((quote) => ({
      lobs: quote.LOBs,
      email: quote.applicantEmail,
      applicantName: quote.applicantName,
      phoneNumber: quote.applicantPhoneNumber,
      quoteId: quote.id,
      quotedBy: quote.producerName,
      createdDate: quote.quoteDate,
      source: quote.source,
      state: quote.state,
      status: quote.status,
      currentStep: quote.currentStep,
      isNewQuoteConnectorQuote: true,
    }));
  } catch (error) {
    yield put(errorHandler(error));
    return [];
  }
}

function* fetchOldQuotesList({ payload: { producerId } }) {
  const quotesWithMetadata = yield fetchOldQuotesMappedWithMetadata({
    payload: { producerId },
  });
  yield put(setQuotesList(quotesWithMetadata));
}

function* fetchProducerQuotesOptionsFun({ payload }) {
  const { auth, impersonate } = yield select();
  const { producer, isImpersonate } = impersonate;
  const { producer_id } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  try {
    yield put(setProducerQuotesOptionsLoader(true));
    let producerQuoteOptions;
    if (!!payload) {
      producerQuoteOptions = yield axios.get(
        `api/v1/producer/details/${producer_id}/${payload}`
      );
    } else {
      producerQuoteOptions = yield axios.get(
        `api/v1/producer/details/${producer_id}`
      );
    }
    if (
      producerQuoteOptions.data.success &&
      !!producerQuoteOptions?.data?.appointments
    ) {
      const { appointments, hasAppointment } = producerQuoteOptions.data;
      yield put(
        setProducerQuoteOptionsList({
          appointments,
          hasAppointment,
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }

  yield put(setProducerQuotesOptionsLoader(false));
}

export function* fetchProducerQuotesOptionsWatcher() {
  yield takeEvery(
    dashboardActions.FETCH_PRODUCER_QUOTE_OPTIONS,
    fetchProducerQuotesOptionsFun
  );
}

function* fetchCarrierCredentialFun({ payload }) {
  const { auth, impersonate } = yield select();
  const { producer, isImpersonate } = impersonate;
  const { producer_id } = getAuthDetails({
    auth,
    producer,
    isImpersonate,
  });
  const {
    carrierId,
    userName,
    password,
    producerCode,
    carrierProducerAppointmentId,
  } = payload;

  try {
    yield put(carrierCredentialLoader(true));
    yield put(setCarrierProducerAppointmentId(null));

    const body = {
      carrierId,
      producerId: producer_id,
      username: userName,
      password,
      producerCode,
    };

    if (carrierProducerAppointmentId) {
      body.carrierProducerAppointmentId = carrierProducerAppointmentId;
    }

    const response = yield axios.post(
      'api/v1/carrier/update-carrier-producer-credential',
      body
    );

    if (response?.data?.success && response.data.carrierProducerCredential) {
      yield put(
        setCarrierProducerAppointmentId({
          id: response.data.carrierProducerCredential
            .carrierProducerAppointmentId,
          carrierId,
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
    yield put(setCarrierProducerAppointmentId(null));
  } finally {
    yield put(carrierCredentialLoader(false));
  }
}

export function* fetchCarrierCredentialWatcher() {
  yield takeEvery(
    dashboardActions.FETCH_CARRIER_CREDENTIAL,
    fetchCarrierCredentialFun
  );
}

function* accessQuotingPageFun({ payload }) {
  try {
    yield put(setAccessQuotingPageLoader(true));
    const response = yield axios.post('/api/v1/partner/launchurl', {
        selectedLineOfBusinesses: [payload?.LOB],
        producerId: payload?.producerId,
      });
    if (response.data.success && !!response?.data?.launchUrl) {
      const { launchUrl } = response.data;
      yield put(setLaunchUrl({ launchUrl }));
    }
    yield put(setAccessQuotingPageLoader(false));
  } catch (error) {
    yield put(setAccessQuotingPageLoader(false));
    yield put(errorHandler(error));
  }
  yield put(setAccessQuotingPageLoader(false));
}

function* accessQuotingPageWatcher() {
  yield takeEvery(
    rolePermissionActions.ACCESS_QUOTING_PAGE,
    accessQuotingPageFun
  );
}

function* updateQuoteConnectorNotificationVisibility({ payload: { quoteId } }) {
  try {
    yield put(setQuoteNotificationIndicatorLoader(true));
    const redux = yield select();
    const { quotesList, showQuotes } = redux.dashboard;
    let updatedQuoteList = quotesList;
    const updateIndicator = yield axios.post(
      'api/v1/quote/update-notification',
      {
        id: quoteId,
        entity: ENTITY_QUOTE,
      }
    );
    if (!!updateIndicator?.data?.success) {
      let updatedQuoteIndex = updatedQuoteList.findIndex(
        (item) => item.quoteId === quoteId
      );
      updatedQuoteList[updatedQuoteIndex].isNew = false;
      yield put(
        setQuotesList({ quotesList: updatedQuoteList, showQuotes: showQuotes })
      );
    } else {
      yield put(setQuotesList({ quotesList, showQuotes }));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setQuoteNotificationIndicatorLoader(false));
}

export function* updateQuoteConnectorNotification() {
  yield takeEvery(
    dashboardActions.UPDATE_QUOTE_NOTIFICATION_INDICATOR,
    updateQuoteConnectorNotificationVisibility
  );
}

export function* markAppointedCarriersViewed({ payload }) {
  try {
    yield axios.post('api/v1/producer/mark-appointed-carriers-viewed', {
      carrierIds: payload,
    });
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* markAppointedCarriersViewedWatcher() {
  yield takeEvery(
    dashboardActions.MARK_APPOINTED_CARRIERS_AS_VIEWED,
    markAppointedCarriersViewed
  );
}

function* setCarrierStoreFlowViewed() {
  try {
    const { auth, impersonate } = yield select();
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });

    yield axios.post('api/v1/producer/mark-carrier-flow-modal-viewed', {
      producerId: producer_id,
    });
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* setCarrierStoreFlowViewedWatcher() {
  yield takeEvery(
    dashboardActions.SET_CARRIER_FLOW_VIEWED,
    setCarrierStoreFlowViewed
  );
}

function* setFastStartModalViewed({ payload }) {
  try {
    const { auth, impersonate } = yield select();
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });
    yield axios.post('api/v1/producer/mark-fast-start-modal-viewed', {
      producerId: producer_id,
      fastStartModal: payload,
    });
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* setFastStartModalViewedWatcher() {
  yield takeEvery(
    dashboardActions.SET_FAST_START_MODAL_VIEWED,
    setFastStartModalViewed
  );
}

function* fetchFastStartPolicies() {
  try {
    yield put(setFastStartPolicyListLoader(true));
    const { auth, impersonate } = yield select();
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });

    const response = yield axios.get(
      `api/v1/producer/fast-start-policies/${producer_id}`
    );
    if (!!response.data) {
      const { fastStartDetails, appointedCarriers } = response.data;
      yield put(
        setFastStartPolicyList({
          fastStartDetails: fastStartDetails,
          appointedCarriers: appointedCarriers,
        })
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setFastStartPolicyListLoader(false));
}

export function* fetchFastStartPoliciesWatcher() {
  yield takeEvery(
    dashboardActions.FETCH_FAST_START_POLICIES,
    fetchFastStartPolicies
  );
}

function* addFastStartPolicy({ payload }) {
  try {
    const { auth, impersonate } = yield select();
    const { producer, isImpersonate } = impersonate;
    const { producer_id } = getAuthDetails({
      auth,
      producer,
      isImpersonate,
    });

    yield put(setAddFastStartPolicyLoader(true));
    const response = yield axios.post('api/v1/producer/add-fast-start-policy', {
      carrierId: payload.carrierId,
      policyNumber: payload.policyNumber,
      policyPremium: payload.policyPremium,
      policyEffectiveDate: payload.policyEffectiveDate,
      producerId: producer_id,
    });

    if (response.data.success) {
      const { fastStartPolicy } = response.data;
      yield displaySuccessMessage(response.data.message);
      yield put(setAddedFastStartDetails(fastStartPolicy));
      yield put(setIsFastStartAdded(true));
      yield put(setIsFastStartPolicyAddedd(true));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setAddFastStartPolicyLoader(false));
}

export function* addFastStartPolicyWatcher() {
  yield takeEvery(dashboardActions.ADD_FAST_START_POLICIES, addFastStartPolicy);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchPolicyPremiumDetailsWatcher),
    fork(fetchCarrierPortalsDetailsWatcher),
    fork(fetchUpcomingRenewalDetailsWatcher),
    fork(getCarrierLinkWatcher),
    fork(fetchQuotesListWatcher),
    fork(updateQuoteConnectorNotification),
    fork(fetchProducerQuotesOptionsWatcher),
    fork(accessQuotingPageWatcher),
    fork(fetchCarrierCredentialWatcher),
    fork(markAppointedCarriersViewedWatcher),
    fork(setCarrierStoreFlowViewedWatcher),
    fork(setFastStartModalViewedWatcher),
    fork(fetchFastStartPoliciesWatcher),
    fork(addFastStartPolicyWatcher),
  ]);
}
