import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ProcessHeader from 'components/process-header';
import ProcessFooter from 'components/process-footer';
import SubmitLoader from 'components/submit-loader';
import {
  setUpdatePasswordToken,
  updatePassword,
  validateEmailToken,
} from 'redux/actions/update-password';
import { setInitUrl } from 'redux/actions/auth';
import { fetchOrganizationData } from 'redux/actions/organization-details';
import { globalMessages } from 'util/extra';
import getQueryParams from 'util/query-string';
import { password_regex } from 'util/sign-up-process';
import {
  CARRIER_TOOLTIP,
  PASSWORD_DO_NO_MATCHED,
  REQUIRED,
} from 'constants/constant';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

const styles = () => ({
  fieldError: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    top: '0px',
    left: '0px',
    fontSize: '13px',
    color: '#f00',
  },
  forgotLink: {
    float: 'right',
    paddingTop: '5px',
  },
  lockIcon: {
    fontSize: '24px',
  },
});
import ResetPwdLink from 'components/reset-pwd-link/index';
import DancingDots from 'components/dancing-dots';
class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      newPassword: '',
      confirmPassword: '',

      inputError: {
        newPasswordError: '',
        confirmPasswordError: '',
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    this.props.fetchOrganizationData();
    const id = getQueryParams(location.search, 'id');

    if (id) {
      this.props.validateEmailToken(id);
    } else {
      this.props?.setInitUrl('/signin');
      this.props?.history?.push('/signin');
    }
  }

  componentDidUpdate() {
    const { isTokenValid, location } = this.props;
    const id = getQueryParams(location.search, 'id');
    if (id) {
      if (isTokenValid && !!isTokenValid) {
        this.props?.setUpdatePasswordToken(id);
      }
    }
  }

  onSignInSubmit = (e) => {
    const { newPassword } = this.state;
    if (this.validate()) {
      this.props.updatePassword({
        new_password: newPassword,
      });
    }
    localStorage.removeItem(CARRIER_TOOLTIP);
    e.preventDefault();
  };

  validate = () => {
    const { newPassword, confirmPassword } = this.state;
    let inputError = {
      newPasswordError: '',
      confirmPasswordError: '',
    };
    if (!newPassword.length > 0) {
      inputError.newPasswordError = REQUIRED;
    }
    if (newPassword.length > 0 && !password_regex.test(newPassword)) {
      inputError.newPasswordError = globalMessages.passwordMessage;
    }
    if (!confirmPassword.length > 0) {
      inputError.confirmPasswordError = REQUIRED;
    }
    if (
      newPassword !== confirmPassword &&
      confirmPassword.length > 0 &&
      newPassword.length > 0
    ) {
      inputError.confirmPasswordError = PASSWORD_DO_NO_MATCHED;
    }
    this.setState({
      inputError,
    });
    if (inputError.newPasswordError || inputError.confirmPasswordError) {
      return false;
    }
    return true;
  };

  render() {
    const { newPassword, confirmPassword, inputError } = this.state;
    const {
      loader,
      classes,
      updatingPassword,
      updatePasswordCompleted,
      isTokenValid,
      producerEmail,
    } = this.props;
    const isEnabled = !isEmpty(newPassword) && !isEmpty(confirmPassword);

    return (
      <div className="app-container post-signup-wrapper login-container">
        <div className="app-main-container">
          <div className={`app-header`}>
            <ProcessHeader />
          </div>

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              {loader ? (
                <div className="loader-view">
                  <DancingDots fullpageWrapper />
                </div>
              ) : isTokenValid ? (
                <>
                  <div
                    className="section no-pad-bot blue-grey lighten-5"
                    id="sign-up-form-field"
                  >
                    <div className="container">
                      <div className="change-password-form d-flex justify-content-center animated slideInUpTiny animation-delay-1">
                        {!updatePasswordCompleted ? (
                          <Card className="change-pwd-card">
                            <form onSubmit={this.onSignInSubmit}>
                              <CardContent className="change-pwd-content border-bottom">
                                <div className="row">
                                  <div
                                    className="col-sm-12 sign-up-form signin"
                                    onSubmit={this.onSignInSubmit}
                                  >
                                    <Typography className="chnage-pwd-heading">
                                      Change Password
                                    </Typography>
                                    <Typography className="chnage-pwd-info-label">
                                      Use 8+ characters with uppercase,
                                      lowercase, and numbers
                                    </Typography>

                                    <div className="row">
                                      <div className="input-field col-sm-12 col-md-12 col-lg-12">
                                        <input
                                          id="newPassword"
                                          type="password"
                                          value={newPassword}
                                          className="fs-exclude validate"
                                          onChange={(event) =>
                                            this.setState({
                                              newPassword: event.target.value,
                                            })
                                          }
                                          disabled={updatingPassword}
                                        />
                                        <label
                                          htmlFor="newPassword"
                                          className="active"
                                        >
                                          New Password
                                          <span className="required">*</span>
                                        </label>
                                        <div className={classes.fieldError}>
                                          {inputError.newPasswordError}
                                        </div>
                                      </div>

                                      <div className="input-field col-sm-12 col-md-12 col-lg-12">
                                        <input
                                          id="confirmPassword"
                                          type="password"
                                          value={confirmPassword}
                                          className="fs-exclude validate"
                                          onChange={(event) =>
                                            this.setState({
                                              confirmPassword:
                                                event.target.value,
                                            })
                                          }
                                          disabled={updatingPassword}
                                        />
                                        <label
                                          htmlFor="confirmPassword"
                                          className="active"
                                        >
                                          Confirm Password
                                          <span className="required">*</span>
                                        </label>
                                        <div className={classes.fieldError}>
                                          {inputError.confirmPasswordError}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CardContent>
                              <CardActions className="change-pwd-action d-flex justify-content-center">
                                <button
                                  className={`${
                                    !isEnabled ? 'disable' : ''
                                  } update-password-button`}
                                  disabled={updatingPassword || !isEnabled}
                                >
                                  Update
                                  {updatingPassword && <SubmitLoader />}
                                </button>
                              </CardActions>
                            </form>
                          </Card>
                        ) : (
                          <Card>
                            <CardContent>
                              <div className="font-weight-bold pt-2">
                                <div className="mt-2 pr-1">
                                  {globalMessages.changePasswordMessage}
                                </div>
                                <div className=" mt-2">
                                  <a
                                    href="/signin"
                                    className="orange-text reset-link"
                                    rel="noopener noreferer"
                                  >
                                    <span className="nav-text orange-text">
                                      Return to login
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <ResetPwdLink producerEmail={producerEmail} />
              )}
            </div>
            <ProcessFooter />
          </main>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, passwordSettings }) => {
  const { authUser } = auth;
  const {
    loader,
    passwordToken,
    updatingPassword,
    updatePasswordCompleted,
    isTokenValid,
    producerEmail,
  } = passwordSettings;
  return {
    loader,
    authUser,
    passwordToken,
    updatingPassword,
    updatePasswordCompleted,
    isTokenValid,
    producerEmail,
  };
};

export default connect(mapStateToProps, {
  fetchOrganizationData,
  setUpdatePasswordToken,
  updatePassword,
  setInitUrl,
  validateEmailToken,
})(withStyles(styles)(ChangePassword));
