import * as reportsActions from '../../constants/action-types';

export const fetchAgentOnboardReport = (payload) => ({
  type: reportsActions.FETCH_AGENT_ONBOARD_REPORT,
  payload,
});

export const setAgentOnboardReport = (payload) => ({
  type: reportsActions.SET_ONBOARD_REPORT,
  payload,
});

export const fetchAgentSyncReport = (payload) => ({
  type: reportsActions.FETCH_AGENT_SYNC_REPORT,
  payload,
});

export const setAgentSyncReport = (payload) => ({
  type: reportsActions.SET_AGENT_SYNC_REPORT,
  payload,
});

export const fetchFastStartReport = (payload) => ({
  type: reportsActions.FETCH_FAST_START_REPORT,
  payload,
});

export const setFastStartReport = (payload) => ({
  type: reportsActions.SET_FAST_START_REPORT,
  payload,
});

export const fetchMonthlyCommissionDate = (payload) => ({
  type: reportsActions.FETCH_MONTHLY_COMMISSION_DATE,
  payload,
});

export const setMonthlyCommissionDate = (payload) => ({
  type: reportsActions.SET_MONTHLY_COMMISSION_DATE,
  payload,
});

export const fetchMonthlyCommissionReport = (payload) => ({
  type: reportsActions.FETCH_MONTHLY_COMMISSION_REPORT,
  payload,
});
export const setMonthlyCommissionLoader = (payload) => ({
  type: reportsActions.MONTHLY_COMMISSION_DATE_LOADER,
  payload,
});

export const setMonthlyCommissionReport = (payload) => ({
  type: reportsActions.SET_MONTHLY_COMMISSION_REPORT,
  payload,
});

export const fetchAppointmentFailureReport = (payload) => ({
  type: reportsActions.FETCH_APPOINTMENT_FAILURE_REPORT,
  payload,
});

export const setAppointmentFailureReport = (payload) => ({
  type: reportsActions.SET_APPOINTMENT_FAILURE_REPORT,
  payload,
});

export const fetchQuickbooksExtractReport = (payload) => ({
  type: reportsActions.FETCH_QUICKBOOKS_EXTRACT_REPORT,
  payload,
});

export const setQuickbooksreport = (payload) => ({
  type: reportsActions.SET_QUICKBOOKS_REPORT,
  payload,
});

export const fetchCommissionReport = (payload) => ({
  type: reportsActions.FETCH_COMMISSION_REPORT,
  payload,
});

export const setCommissionReport = (payload) => ({
  type: reportsActions.SET_COMMISSION_REPORT,
  payload,
});

export const getMotionAutoReport = (data) => ({
  type: reportsActions.FETCH_MOTION_AUTO_LIST,
  payload: data,
});

export const setMotionAutoReport = (data) => ({
  type: reportsActions.SET_MOTION_AUTO_REPORT,
  payload: data,
});

export const motionAutoListLoader = (payload) => ({
  type: reportsActions.MOTION_AUTO_LIST_LOADER,
  payload,
});

export const fetchIncentiveReport = (payload) => ({
  type: reportsActions.FETCH_INCENTIVE_REPORT,
  payload,
});

export const setIncentiveReport = (payload) => ({
  type: reportsActions.SET_INCENTIVE_REPORT,
  payload,
});

export const fetchCarrierLobMarketReport = () => ({
  type: reportsActions.FETCH_CARRIER_LOB_MARKET_REPORT,
});

export const carrierLobMarketReportLoader = (payload) => ({
  type: reportsActions.CARRIER_LOB_MARKET_REPORT_LOADER,
  payload,
});
