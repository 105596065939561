import * as types from '../../constants/action-types';
import { find, findKey } from 'lodash';

const initState = () => ({
  loader: false,
  statementList: [],
  statementDetails: [],
  isUploading: false,
  uploaded: false,

  selectedMonth: '',
  monthly_commission_id: '',
  selectedMonthStatus: '',

  // on 'continue' for pending carrier list modal
  reviewDocumentLoader: false,
  reviewCommissionReportLink: '',
  reviewPrenoteLink: '',
  reviewNachaLink: '',
  failedNachaRecordsLink: '',
  publishLoader: false,
  monthAlreadyProcessed: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.INIT_URL: {
      return { ...state, initURL: action.payload };
    }

    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        isUploading: false,
      };
    }

    // monthly list
    case types.SET_COMMISSION_DETAILS: {
      return {
        ...state,
        statementList: [...action.payload],
      };
    }

    case types.SET_COMMISSION_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }

    // statement view details
    case types.SET_STATEMENT_DATA: {
      return {
        ...state,
        statementDetails: action.payload,
        loader: false,
      };
    }

    case types.COMMISSION_FILES_UPLOAD_SUCCESS: {
      return {
        ...state,
        statementDetails: {
          ...state.statementDetails,
          [action.payload.index]: {
            ...state.statementDetails[action.payload.index],
            file: action.payload.fileurl,
          },
        },
        uploaded: false,
      };
    }

    case types.CARRIER_COMMISSION_UPDATE: {
      return {
        ...state,
        statementDetails: {
          ...state.statementDetails,
          [action.payload.index]: {
            ...state.statementDetails[action.payload.index],
            ...action.payload.uploadResponse,
          },
        },
        isUploading: false,
        uploaded: true,
      };
    }

    case types.COMMISSION_FILES_UPLOADING: {
      return {
        ...state,
        isUploading: true,
      };
    }

    case types.COMMISSION_FILES_UPLOAD_FAILED: {
      return {
        ...state,
        isUploading: false,
        uploaded: false,
      };
    }

    case types.COMMISSION_FILES_UPLOAD_SUCCESS_FLAG: {
      return {
        ...state,
        isUploading: false,
        uploaded: true,
      };
    }

    case types.UPDATE_MONTH_DETAILS: {
      return {
        ...state,
        selectedMonth: action.payload.selectedMonth,
        monthly_commission_id: action.payload.monthly_commission_id,
        selectedMonthStatus: action.payload.status,
      };
    }

    case types.SET_REIVEW_DOCUMENTS: {
      return {
        ...state,
        reviewCommissionReportLink: action.payload.commission_report,
        reviewPrenoteLink: action.payload.prenote,
        reviewNachaLink: action.payload.nacha_file,
        failedNachaRecordsLink: action.payload.failedNachaRecords,
      };
    }

    case types.REVIEW_DOCUMENT_LOADER: {
      return {
        ...state,
        reviewDocumentLoader: action.payload,
      };
    }

    case types.PUBLISH_LOADER: {
      return {
        ...state,
        publishLoader: action.payload,
      };
    }

    case types.MONTH_ALREADY_PROCEED: {
      return {
        ...state,
        monthAlreadyProcessed: action.payload,
      };
    }

    case types.STORE_DELETED_COMMISSION_RECORD: {
      let deletedRecord = find(state.statementDetails, [
        'id',
        action.payload.payout_file_id,
      ]);
      const recordKey = findKey(
        state.statementDetails,
        record => record.id === action.payload.payout_file_id
      );

      // clear deleted record fields.
      deletedRecord = {
        ...deletedRecord,
        s3: null,
        processing_summary: null,
        file: null,
        processed_records: null,
        total_records: null,
        status: null,
      };

      return {
        ...state,
        statementDetails: {
          ...state.statementDetails,
          [recordKey]: {
            ...deletedRecord,
          },
        },
      };
    }

    // case types.STORE_TRIGGER_STATEMENT_CREATION_AND_NOTIFICATION: {
    //   const { id } = action.payload;
    //   let newStatementList = [...state.statementList];
    //   const elementsIndex = state.statementList.findIndex(
    //     element => element.id === id
    //   );
    //   newStatementList[elementsIndex] = {
    //     ...newStatementList[elementsIndex],
    //     show_button: action.payload.show_button,
    //     statement_status: action.payload.statement_status,
    //   };
    //   return {
    //     ...state,
    //     statementList: [...newStatementList],
    //   };
    // }

    default:
      return state;
  }
};
