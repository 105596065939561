import { all, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import * as getOrganizationDetails from 'constants/action-types';
import {
  organizationDataLoader,
  setOrganizationData,
} from 'redux/actions/organization-details';
import { errorHandler } from 'redux/actions/error';

function* fetchOrganizationData() {
  try {
    yield put(organizationDataLoader(true));
    let response = yield axios.get(`/api/v1/organization/get-details`);
    if (response.status === 200) {
      const { organization } = response.data;
      localStorage.setItem('organizationDetails', JSON.stringify(organization));
      window.top.document.title = organization.organizationName;
      yield put(setOrganizationData(organization));
    }
    // TODO: else
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(organizationDataLoader(false));
}

export function* organizationDetailsWatcher() {
  yield takeEvery(
    getOrganizationDetails.FETCH_ORGANIZATION_DATA,
    fetchOrganizationData
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(organizationDetailsWatcher)]);
}
