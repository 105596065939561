// @ts-check

import * as authType from '../../constants/action-types';

export const setInitUrl = (url) => {
  return {
    type: authType.INIT_URL,
    payload: url,
  };
};

export const userSignIn = (user) => ({
  type: authType.SIGNIN_USER,
  payload: {
    ...user,
  },
});

/**
 * Updates the state of the sign-in form.
 * @param {'emailStandalone' | 'loading' | 'passwordless' | null} customStep - new state
 * @returns {Object} - The action object with the updated state.
 */
export const updateSignInFormCustomStep = (customStep) => ({
  type: authType.UPDATE_SIGN_IN_FORM_CUSTOM_STEP,
  payload: customStep,
});

/**
 * Sets global loader.
 * @param {boolean} isShown
 * @returns {Object} - The action object with the updated state.
 */
export const setIsGlobalAuthLoaderShown = (isShown) => ({
  type: authType.SET_IS_GLOBAL_AUTH_LOADER_SHOWN,
  payload: isShown,
});

export const checkIsPasswordless = (email) => ({
  type: authType.CHECK_IS_PASSWORDLESS,
  payload: email,
});

export const signinAuth0HandleRedirect = () => ({
  type: authType.SIGNIN_AUTH0_HANDLE_REDIRECT,
  payload: {},
});

export const signinAuth0FetchUserData = (payload) => ({
  type: authType.SIGNIN_AUTH0_FETCH_USER_DATA,
  payload,
});

export const fetchPermissions = (payload) => ({
  type: authType.FETCH_PERMISSIONS,
  payload,
});

export const userAutoSignIn = (details) => ({
  type: authType.AUTO_SIGNIN,
  payload: details,
});

export const userSignInSuccess = (details) => ({
  type: authType.SIGNIN_USER_SUCCESS,
  payload: details,
});

export const userSignOut = (payload) => ({
  type: authType.SIGNOUT_USER,
  payload,
});

export const userSignOutSuccess = () => ({
  type: authType.SIGNOUT_USER_SUCCESS,
});

export const authLoaderShow = () => ({
  type: authType.ON_SHOW_LOADER,
});

export const authLoaderHide = () => ({
  type: authType.ON_HIDE_LOADER,
});

export const showAuthMessage = (message) => ({
  type: authType.SHOW_MESSAGE,
  payload: message,
});

export const hideMessage = () => ({
  type: authType.HIDE_MESSAGE,
});

export const setIsUserEmailVerified = (payload) => ({
  type: authType.SET_IS_USER_EMAIL_VERIFIED,
  payload,
});

// used to set dashboard view OR post signup view based on 'dashboard' flag
export const setDashboardView = (flag) => ({
  type: authType.SET_DASHBOARD_VIEW,
  payload: flag,
});

export const setProducerLoggedIn = (payload) => ({
  type: authType.SET_PRODUCER_LOGGEDIN,
  payload: payload,
});

// when agency profile first name updates so we need to update login user name as well.
export const setAuthUserName = (payload) => ({
  type: authType.UPDATE_AUTH_NAME,
  payload,
});

export const setAuthUserLastName = (payload) => ({
  type: authType.UPDATE_AUTH_LAST_NAME,
  payload,
});

// Update compliance status
export const updateComplianceStatus = (payload) => ({
  type: authType.UPDATE_COMPLIANCE_STATUS,
  payload,
});

export const updateDefaultPasswordFlag = (payload) => ({
  type: authType.UPDATE_DEFAULT_PASSWORD,
  payload,
});

export const setIsWholesaler = (flag) => ({
  type: authType.SET_WHOLESALER,
  payload: flag,
});

export const setCarrierFloodRater = (payload) => ({
  type: authType.SET_CARRIER_FLOOD_RATER,
  payload,
});

export const setCarrierTend = (payload) => ({
  type: authType.SET_CARRIER_TEND,
  payload,
});

export const setCloudFrontUrl = (payload) => ({
  type: authType.SET_CLOUD_FRONT_URL,
  payload,
});

export const setCarrierTooltip = (payload) => ({
  type: authType.SET_CARRIER_TOOLTIP,
  payload,
});

export const updateSSNEditPrompt = (flag) => ({
  type: authType.UPDATE_SSN_EDIT_PROMPT_VALUE,
  payload: flag,
});

export const setAuthInfo = (payload) => ({
  type: authType.SET_AUTH_INFO,
  payload,
});

export const setCrossSellDetails = (flag) => ({
  type: authType.SET_CROSS_SELL_DETAILS,
  payload: flag,
});

export const setCrossSellViewed = (payload) => {
  return {
    type: authType.SET_CROSS_SELL_VIEWED,
    payload,
  };
};

export const getProgressBarFlag = (payload) => {
  return {
    type: authType.GET_PROGRESS_BAR_FLAG,
    payload,
  };
};

export const setProgressBarFlag = (flag) => ({
  type: authType.SET_PROGRESS_BAR_FLAG,
  payload: flag,
});

export const getProgressBarLoader = (flag) => ({
  type: authType.SET_PROGRESS_BAR_Loader,
  payload: flag,
});

export const setClickOnCrossSell = (flag) => ({
  type: authType.SET_CLICK_ON_CROSS_SALE,
  payload: flag,
});

export const setApplicationModal = (flag) => ({
  type: authType.SET_CARRIER_STORE_APPLICATION_MODAL,
  payload: flag,
});

export const setCarrierStoreDetails = (details) => ({
  type: authType.SET_CARRIER_STORE_DETAILS,
  payload: details,
});

export const updateCarrierAppointmentRequestCount = (payload) => ({
  type: authType.UPDATE_CARRIER_APPOINTMENT_REQUEST_COUNT,
  payload,
});

export const carrierStoreThankYouModalClose = (payload) => ({
  type: authType.CARRIER_STORE_THANK_YOU_MODAL_CLOSE,
  payload,
});

export const setDontUpdateMe = (flag) => ({
  type: authType.SET_DONT_UPDATE_ME,
  payload: flag,
});

export const setStateInitialized = (payload) => ({
  type: authType.SET_STATE_INITIALIZED,
  payload,
});

export const setProducer = (producer) => ({
  type: authType.SET_PRODUCER,
  payload: producer,
});

export const fetchNotifications = () => ({
  type: authType.FETCH_NOTIFICATIONS,
});

export const setUnseenNotificationsCount = (unseenNotificationsCount) => ({
  type: authType.SET_UNSEEN_NOTIFICATIONS_COUNT,
  payload: unseenNotificationsCount
});
