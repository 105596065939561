import React, { Component } from 'react';
import { TRAILING_SLASH_URL } from 'constants/constant';
import { connect } from 'react-redux';
import { setInitUrl } from 'redux/actions/auth';
import { fetchOrganizationData } from 'redux/actions/organization-details';
import { setSignupByReferral } from 'redux/actions/post-sign-up';
import { preSignupUser, preSignupUserSuccess } from 'redux/actions/pre-sign-up';
import { updateAgentNameInSignUpProcess } from 'redux/actions/dashboard';
import './sign-up-page.scss';
import { Redirect, Route, Switch } from 'react-router-dom';
import { queryParam } from 'util/extra';

class SignupProcess extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  componentDidMount() {
    queryParam(this.props);
  }

  render() {
    const {
      match,
      location: { pathname },
    } = this.props;
    return (
      <React.Fragment>
        <Switch>
          <Redirect from={TRAILING_SLASH_URL} to={pathname.slice(0, -1)} />
          <Route exact to={`${match.url}/post-signup`} />
        </Switch>
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({
  preSignup,
  auth,
  organizationDetails,
  myAccount,
}) => {
  const { teamMemberEmailDuplicate } = myAccount;
  const { loader, signupStatus, emailError, existStatus } = preSignup;
  const { authUser } = auth;
  return {
    loader,
    signupStatus,
    authUser,
    organizationDetails,
    teamMemberEmailDuplicate,
    emailError,
    existStatus,
  };
};

export default connect(mapStateToProps, {
  fetchOrganizationData,
  preSignupUser,
  setSignupByReferral,
  preSignupUserSuccess,
  setInitUrl,
  updateAgentNameInSignUpProcess,
})(SignupProcess);
