// @ts-check
import Cookies from 'universal-cookie';
import { EMAIL_VERIFICATION_STARTED_COOKIE_KEY } from 'constants/constant';

export function setEmailVerificationStartedCookie() {
  const cookies = new Cookies();
  const cookieOptions = {
    secure: true,
    path: '/',
    maxAge: 604800, // 7 days expiration time
  };

  cookies.set(EMAIL_VERIFICATION_STARTED_COOKIE_KEY, true, cookieOptions);
}

export function clearEmailVerificationStartedCookie() {
  const cookies = new Cookies();
  cookies.remove(EMAIL_VERIFICATION_STARTED_COOKIE_KEY, { path: '/' });
}

export function hasFinishedEmailVerification(isUserEmailVerified) {
  const cookies = new Cookies();
  const wasEmailVerificationStarted =
    cookies.get(EMAIL_VERIFICATION_STARTED_COOKIE_KEY) === 'true';

  return wasEmailVerificationStarted && isUserEmailVerified;
}
