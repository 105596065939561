import React, { Component } from 'react';
import { Backdrop, Card, Container, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import GraphLoader from 'components/graph-loader';
import PostSignupHeader from 'components/post-signup-header';
import {
  AGENCY_NPN_PREFILLED,
  ALTERNATE_SUB_PRODUCER,
  ENO,
  MAGIC_LINK,
  MAGIC_LINK_USED,
  MAPPING_POST_SIGNUP_STEPS_NAME,
  NO,
  OWNER_NPN_PREFILLED,
  STEP_1,
  STEP_2,
  STEP_3,
  STEP_4,
  STEP_5,
  STEP_7,
  SUB_PRODUCER_KEY,
  YES,
} from 'constants/constant';
import { APPLICATION } from 'constants/heap-events';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { setDashboardView, setProducerLoggedIn } from 'redux/actions/auth';
import { fetchOrganizationData } from 'redux/actions/organization-details';
import { getAccessibleSteps } from 'util/sign-up-process';
import {
  agencyDetailsContent,
  agencyProfileContent,
  enoContent,
  licenseAndEnoContent,
  taxContent,
  toolTipsEnOValue,
  toolTipsLicenseValue,
  toolTipsW9Value,
} from '../../../util/extra';
import IntlMessages from '../../../util/intl-messages';
import Step1 from './step-1';
import Step2 from './step-2';
import Step3 from './step-3';
import Step4 from './step-4';
import Step5 from './step-5';
import Step7 from './step-7';
import StepProgress from './step-progress';
import {
  setCurrentStepIndex,
  setUpdatedSteps,
  stepSubmitSuccess,
} from 'redux/actions/post-sign-up';
import classNames from 'classnames';
import SignUp from './sign-up';
import { fetchIncentiveEarning } from 'redux/actions/incentives';
import { getIsNewSignUpFlowEnabled } from 'util/auth/get-is-new-sign-up-flow-enabled';
import SignUpPasswordless from 'containers/post-signup/post-signup-process/sign-up-passwordless';
import DancingDots from 'components/dancing-dots';

export const PostSignupProcessStyles = {
  mb4: { marginBottom: '1.5rem' },
  card: {
    boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: 0,
    height: '100%',
  },
  cardContent: {
    padding: '21px 25px 48px 25px',
    margin: '0px',
  },
  pocemailroot: {
    '&:before': {
      borderBottom: '1px solid #ccc',
      height: '2.6rem',
    },
    '&:active': {
      '&:before': {
        borderBottom: 'solid 1px #0084c2 !important',
      },
    },
    '&:hover': {
      border: 'none !important',
      '&:before': {
        borderBottom: 'solid 1px #0084c2 !important',
      },
    },
    '&:after': {
      border: 'none !important',
    },
    '& input[type="text"]': {
      border: 'none !important',

      '&:hover, &:focus': {
        border: 'none !important',
      },
    },
  },
  infoIconDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  radioInputHeight: { minHeight: '70px' },
  mt10: { marginTop: '10px !important' },
  mt22: { marginTop: '22px' },
  submitLoader: { marginLeft: '10px' },
  nextBtn: {
    marginLeft: 'auto',
    marginTop: '20px',
  },
  backdrop: { zIndex: 10000 },
  '@media (max-width:600px)': { radioInputHeight: { minHeight: '50px' } },
};

const VISUAL_VIEWPORT_HEIGHT_OFFSET = 72;

class PostSignupProcess extends Component {
  constructor(props) {
    super(props);
    this.productionAccess =
      props.modulePermissionsData?.DASHBOARD?.['PRODUCTION-ACCESS']?.read ||
      false;
    this.accessibleSteps = getAccessibleSteps(
      props.role,
      props.signupFlow,
      this.productionAccess,
      this.props?.writesUnderAgencyLicense
    );
    this.isSubProducer = [ALTERNATE_SUB_PRODUCER, SUB_PRODUCER_KEY].includes(
      props.signupFlow
    );
    this.stepComponents = {
      [STEP_1]: Step1,
      [STEP_2]: Step2,
      [STEP_3]: Step3,
      [STEP_4]: Step4,
      [STEP_5]: Step5,
      [STEP_7]: Step7,
    };
    this.steps = {
      [STEP_1]: {
        stepId: 'agencyProfile',
        title: 'Your Profile',
        content: agencyProfileContent,
        heading: <IntlMessages id="label.your.profile" />,
        pageViewEvent: APPLICATION.VIEW_PROFILE_PAGE,
        stepProgress: 25,
      },
      [STEP_2]: {
        stepId: 'agencyDetails',
        title: 'Agency Details',
        content: agencyDetailsContent,
        heading: <IntlMessages id="label.agency.details" />,
        pageViewEvent: APPLICATION.VIEW_DETAILS_PAGE,
        stepProgress: 37.5,
      },
      [STEP_3]: {
        stepId: 'taxAndW9',
        title: 'Tax and W9',
        content: taxContent,
        heading: <IntlMessages id="label.taxAndW9" />,
        pageViewEvent: APPLICATION.VIEW_TAX_PAGE,
        tooltipsValue: toolTipsW9Value,
      },
      [STEP_4]: {
        stepId: 'license',
        title: 'License',
        content: licenseAndEnoContent,
        heading: !props.isSubProducer ? (
          <IntlMessages id="label.license" />
        ) : (
          <IntlMessages id="admin.agentDirectory.license" />
        ),
        pageViewEvent: APPLICATION.VIEW_LICENSE_PAGE,
        tooltipsValue: toolTipsLicenseValue,
      },
      [STEP_5]: {
        stepId: 'EnO',
        title: 'E&O',
        content: enoContent,
        heading: !props.isSubProducer ? (
          <IntlMessages id="label.eno" />
        ) : (
          <IntlMessages id="admin.agentDirectory.license" />
        ),
        pageViewEvent: APPLICATION.VIEW_EO_PAGE,
        tooltipsValue: toolTipsEnOValue,
      },
      [STEP_7]: {
        stepId: 'agentAgreement',
        title: 'Agent Agreement',
        content: '',
        heading: <IntlMessages id="label.agent.agreement" />,
        pageViewEvent: APPLICATION.VIEW_AGREEMENT_PAGE,
        stepProgress: 87.5,
      },
    };
    this.state = {
      isLoading: false,
      visualViewportHeight:
        window.visualViewport?.height - VISUAL_VIEWPORT_HEIGHT_OFFSET ?? 0,
    };

    this.handleVisualViewportChange =
      this.handleVisualViewportChange.bind(this);
  }

  componentDidMount = () => {
    localStorage.removeItem('signupStatus');
    localStorage.removeItem('email');
    const { signupFlow, authUser } = this.props;
    const isSubProducer = [ALTERNATE_SUB_PRODUCER, SUB_PRODUCER_KEY].includes(
      signupFlow
    );
    const stepValue =
      MAPPING_POST_SIGNUP_STEPS_NAME[this.props.completedSignupStep] || 1;
    if (!isSubProducer && this.props.producerLoggedIn) {
      this.props.setCurrentStepIndex(stepValue);
      this.props.setProducerLoggedIn(false);
    }

    if (
      !isEmpty(this.props?.producerDetails) &&
      this.props.currentStepIndex === 0 &&
      authUser &&
      !isSubProducer
    ) {
      this.props.stepSubmitSuccess();
    }

    const page = localStorage.getItem('page');
    if (
      this.props.signupFlow === ALTERNATE_SUB_PRODUCER &&
      this.productionAccess
    ) {
      if (page === 'license') {
        this.props.setUpdatedSteps(2);
      }
    } else if (
      this.props.signupFlow === SUB_PRODUCER_KEY &&
      this.productionAccess
    ) {
      if (page === 'license') {
        this.props.setUpdatedSteps(1);
      }
    } else {
      if (page === 'eno') {
        this.props.setUpdatedSteps(4);
      } else if (page === 'license') {
        this.props.setUpdatedSteps(3);
      }
    }
    if (!!page) {
      localStorage.setItem('magicLinkPage', page);
    }

    if (this.props.isAgreementAccepted) {
      this.props.setDashboardView(true);
      localStorage.setItem('dashboard', true);
      this.props.history.push('/');
    }
    if (!this.props?.orgDetails) {
      this.props.fetchOrganizationData();
    }
    const currentStep = this.accessibleSteps[this.props.currentStepIndex];
    window.heap.track(this.steps[currentStep].pageViewEvent);
    localStorage.removeItem('page');
    if (window.visualViewport && window.innerWidth <= 600) {
      window.visualViewport.addEventListener(
        'resize',
        this.handleVisualViewportChange
      );
      window.visualViewport.addEventListener(
        'scroll',
        this.handleVisualViewportChange
      );
    }
  };

  componentDidUpdate = (prevProps) => {
    const {
      currentStepIndex,
      licenseAndEnO,
      signupStatus,
      signupFlow,
    } = this.props;
    const isSubProducer = [ALTERNATE_SUB_PRODUCER, SUB_PRODUCER_KEY].includes(
      signupFlow
    );
    if (
      currentStepIndex !== prevProps.currentStepIndex &&
      !isEmpty(this.props?.producerDetails) &&
      this.props.currentStepIndex === 0 &&
      !signupStatus &&
      !isSubProducer
    ) {
      this.props.stepSubmitSuccess();
    }
    if (
      currentStepIndex !== prevProps.currentStepIndex &&
      this.props.currentStepIndex === 1
    ) {
      this.props.fetchIncentiveEarning();
    }

    if (currentStepIndex !== prevProps.currentStepIndex) {
      // to dismount and again mount child components so that they can animate slide up
      this.setState({ isLoading: true }, () => {
        setTimeout(() => this.setState({ isLoading: false }), 200);
      });
      const currentStep = this.accessibleSteps[currentStepIndex];
      const magicToken = localStorage.getItem('magicToken');
      const magicpage = localStorage.getItem('magicLinkPage');
      if (!!magicToken && magicToken) {
        if (!!magicpage && magicpage === ENO && currentStep === STEP_5) {
          window.heap.track(APPLICATION.VIEW_EO_PAGE, {
            [MAGIC_LINK]: true,
          });
          window.heap?.addUserProperties({
            [MAGIC_LINK_USED]: true,
          });
        } else {
          window.heap.track(this.steps[currentStep].pageViewEvent);
        }
      } else {
        window.heap.track(this.steps[currentStep].pageViewEvent);
      }

      if (currentStep === STEP_4) {
        window.heap.track(APPLICATION.LICENSE_PAGE_ENTER, {
          [OWNER_NPN_PREFILLED]: !isEmpty(licenseAndEnO?.individualNPN)
            ? YES
            : NO,
          [AGENCY_NPN_PREFILLED]: !isEmpty(licenseAndEnO?.individualNPN)
            ? YES
            : NO,
        });
      }
    }
  };

  componentWillUnmount = () => {
    if (window.visualViewport) {
      window.visualViewport.removeEventListener(
        'resize',
        this.handleVisualViewportChange
      );
      window.visualViewport.removeEventListener(
        'scroll',
        this.handleVisualViewportChange
      );
    }
  };

  getCurrentStepComponent = (stepProps) => {
    const currentStep = this.accessibleSteps?.[this.props.currentStepIndex];
    const Step = this.stepComponents[currentStep];
    return (
      <Step
        {...stepProps}
        visualViewportHeight={this.state.visualViewportHeight}
      />
    );
  };

  handleVisualViewportChange = (event) => {
    const visualViewportHeight =
      event.target.height +
      event.target.offsetTop -
      VISUAL_VIEWPORT_HEIGHT_OFFSET;

    this.setState((previousState) => ({
      ...previousState,
      visualViewportHeight,
    }));
  };

  render = () => {
    const {
      stepLoader,
      classes,
      isLoading,
      currentStepIndex,
      completedStepsCount,
      niprLoader,
      signupFlow,
      signupStatus,
      isSignedUpByReferral,
      userStatus,
    } = this.props;
    const currentStep = this.accessibleSteps?.[currentStepIndex];
    if (stepLoader || isLoading) {
      return (
        <div className="loader-view">
          <DancingDots fullpageWrapper />
        </div>
      );
    }
    const showProgressBar =
      localStorage.getItem('shouldShowProgressBar') === 'undefined'
        ? false
        : JSON.parse(localStorage.getItem('shouldShowProgressBar'));

    const isNewSignUpFlowEnabled = getIsNewSignUpFlowEnabled();
    const shouldShowSignUpScreen =
      (!this.isSubProducer && !signupStatus && userStatus === null) ||
      (isSignedUpByReferral && userStatus === null);

    if (shouldShowSignUpScreen && !isNewSignUpFlowEnabled) {
      return <SignUp history={this.props.history} />;
    }

    if (shouldShowSignUpScreen && isNewSignUpFlowEnabled) {
      return <SignUpPasswordless history={this.props.history} />;
    }

    return (
      <div>
        <div className="section blue-grey lighten-5 post-signup-agency signup-process-wrapper app-signup-v">
          <Container maxWidth="md">
            <Backdrop className="licenseNPNdrop" open={niprLoader}>
              <GraphLoader color="inherit" content="Fetching NPN Data" />
            </Backdrop>
            <Grid container item spacing={2}>
              <Grid item xs={12} className={classes.mb4}>
                <PostSignupHeader
                  currentStepIndex={currentStepIndex}
                  completedStepsCount={completedStepsCount}
                  steps={this.steps}
                  accessibleSteps={this.accessibleSteps}
                  isSubProducer={this.isSubProducer}
                  signupFlow={signupFlow}
                  productionAccess={this.productionAccess}
                  shouldShowProgressBar={
                    !!this.props.shouldShowProgressBar &&
                    this.props.shouldShowProgressBar !== null
                      ? this.props.shouldShowProgressBar
                      : showProgressBar !== null
                        ? showProgressBar
                        : true
                  }
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={0}
              direction="row"
              className={
                currentStep !== STEP_1 &&
                'animated slideInUpTiny animation-delay-1'
              }
              justify="center"
            >
              <Grid className="grid-shodow">
                <Grid
                  className={classNames('left-content', {
                    'full-width': currentStep === STEP_7,
                  })}
                >
                  <Card className={classes?.card}>
                    {this.getCurrentStepComponent({
                      classNames: classes,
                      isSubProducer: this.isSubProducer,
                      productionAccess: this.productionAccess,
                      history: this.props.history,
                      final:
                        this.accessibleSteps.length - 1 === currentStepIndex,
                    })}
                  </Card>
                </Grid>
                {currentStep !== STEP_7 && (
                  <Grid className="right-content">
                    <StepProgress
                      signupFlow={signupFlow}
                      accessibleSteps={this.accessibleSteps}
                      classNames={classes}
                      currentStepIndex={currentStepIndex}
                      steps={this.steps}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    );
  };
}

const mapStateToProps = ({
  auth: {
    signupFlow,
    isWholesaler,
    shouldShowProgressBar,
    progressBarLoader,
    writesUnderAgencyLicense,
    completedSignupStep,
    authUser,
    userStatus,
    producerLoggedIn,
    role,
  },
  modulePermissions: { modulePermissionsData },
  postSignup: {
    stepLoader,
    currentStepIndex,
    completedStepsCount,
    niprLoader,
    isAgreementAccepted,
    licenseAndEnO,
    isSignedUpByReferral,
    referralData,
  },
  dashboard: { producerDetails },
  organizationDetails: { orgDetails },
  preSignup: { signupStatus },
}) => ({
  signupStatus,
  currentStepIndex,
  completedStepsCount,
  isAgreementAccepted,
  modulePermissionsData,
  niprLoader,
  orgDetails,
  producerDetails,
  signupFlow,
  stepLoader,
  licenseAndEnO,
  isSignedUpByReferral,
  referralData,
  isWholesaler,
  shouldShowProgressBar,
  progressBarLoader,
  writesUnderAgencyLicense,
  completedSignupStep,
  authUser,
  userStatus,
  producerLoggedIn,
  role,
});

export default connect(mapStateToProps, {
  fetchOrganizationData,
  setDashboardView,
  setCurrentStepIndex,
  setUpdatedSteps,
  stepSubmitSuccess,
  setProducerLoggedIn,
  fetchIncentiveEarning,
})(withStyles(PostSignupProcessStyles)(PostSignupProcess));
