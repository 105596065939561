import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { isEmpty } from 'lodash';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import * as getOrganizationDetails from 'constants/action-types';
import {
  organizationFileUploadError,
  setAddUpdateOrganizationLoader,
  setOrganizationLoader,
  storeNewOrganizationData,
  storeOrganizationData,
  storeOrganizationList,
} from 'redux/actions/organizations';
import { errorHandler } from 'redux/actions/error';
import {
  FAV_ICON,
  LOGO,
  ORGANIZATION_MEDIA_FOLDER_NAME,
  SMALL_LOGO,
} from 'constants/constant';
import { uploadMultipleFile } from '../../util/extra';

function* fetchOrganizationList() {
  try {
    yield put(setOrganizationLoader(true));
    let response = yield axios.get(`/api/v1/organization/list`);
    if (
      !!response?.status &&
      response.status === 200 &&
      !!response?.data?.organizationList
    ) {
      const { organizationList } = response.data;
      yield put(storeOrganizationList(organizationList));
    } else {
      yield put(setOrganizationLoader(false));
    }
  } catch (error) {
    if (!!error?.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setOrganizationLoader(false));
}

export function* fetchOrganizationListWatcher() {
  yield takeEvery(
    getOrganizationDetails.FETCH_ORGANIZATION_LIST,
    fetchOrganizationList
  );
}

const createUrl = (fileData, type, orgCode) => {
  const folderName = ORGANIZATION_MEDIA_FOLDER_NAME;
  const file = fileData[0];
  const name = file.name;
  return `${!!orgCode ? orgCode : folderName}/${type}_${name}`;
};

function* addNewOrganizationData({ payload }) {
  try {
    const {
      email,
      orgFavIcon,
      orgLogo,
      orgName,
      orgShortName,
      orgTheme,
      orgUrl,
      orgSmallLogo,
    } = payload;
    yield put(setAddUpdateOrganizationLoader(true));
    yield put(organizationFileUploadError(false));
    const updatedOrgFavIconUrl = !!orgFavIcon
      ? createUrl(orgFavIcon, FAV_ICON, null)
      : '';
    const updatedOrgLogoUrl = !!orgLogo ? createUrl(orgLogo, LOGO, null) : '';
    const updatedOrgSmallLogoUrl = !!orgSmallLogo
      ? createUrl(orgSmallLogo, SMALL_LOGO, null)
      : '';
    const files = [orgFavIcon, orgLogo, orgSmallLogo];
    const filePath = [
      updatedOrgFavIconUrl,
      updatedOrgLogoUrl,
      updatedOrgSmallLogoUrl,
    ];
    const fileResponse = yield call(
      uploadMultipleFile,
      files,
      JSON.stringify(filePath)
    );
    if (!!fileResponse && fileResponse.status === 200) {
      const { files: file_url } = fileResponse.data;
      let response = yield axios.post(`/api/v1/organization`, {
        client_name: orgName.trim(),
        theme: orgTheme,
        logo: file_url[1],
        abbreviation: orgShortName.trim(),
        logo_small: file_url[2],
        logo_favicon: file_url[0],
        domain: orgUrl.trim(),
        support_email: email.trim(),
      });
      if (
        !!response?.status &&
        response.status === 200 &&
        !!response?.data?.success
      ) {
        yield put(storeNewOrganizationData(response.data));
        yield displaySuccessMessage(response.data.message);
      } else {
        yield put(setAddUpdateOrganizationLoader(false));
        yield displayErrorMessage(response.data.message);
      }
    } else {
      yield put(errorHandler(fileResponse));
      yield put(organizationFileUploadError(true));
    }
  } catch (error) {
    if (!!error?.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setAddUpdateOrganizationLoader(false));
}

export function* addNewOrganizationDataWatcher() {
  yield takeEvery(
    getOrganizationDetails.ADD_NEW_ORGANIZATION,
    addNewOrganizationData
  );
}

function* updateOrganizationData({ payload }) {
  try {
    const {
      email,
      orgId,
      orgCode,
      orgFavIcon,
      orgFavIconName,
      orgLogo,
      orgName,
      orgShortName,
      orgTheme,
      orgUrl,
      orgSmallLogo,
      orgLogoFileName,
      orgSmallLogoName,
    } = payload;
    yield put(setAddUpdateOrganizationLoader(true));
    yield put(organizationFileUploadError(false));
    const reduxState = yield select();
    const organizationDetails = reduxState.organizationDetails.orgDetails;
    let updatedUrl = [];
    let imageArray = [
      {
        type: FAV_ICON,
        url: Array.isArray(orgFavIcon) ? orgFavIcon[0] : orgFavIcon,
      },
      { type: LOGO, url: Array.isArray(orgLogo) ? orgLogo[0] : orgLogo },
      {
        type: SMALL_LOGO,
        url: Array.isArray(orgSmallLogo) ? orgSmallLogo[0] : orgSmallLogo,
      },
    ];
    let isFileUploadErrorPresent = false;
    if (!!orgFavIcon && !!orgFavIconName) {
      const updatedOrgFavIconUrl = createUrl(orgFavIcon, FAV_ICON, orgCode);
      updatedUrl.push({
        type: FAV_ICON,
        url: updatedOrgFavIconUrl,
        img: orgFavIcon,
      });
    }
    if (!!orgLogo && !!orgLogoFileName) {
      const updatedOrgLogoUrl = createUrl(orgLogo, LOGO, orgCode);
      updatedUrl.push({ type: LOGO, url: updatedOrgLogoUrl, img: orgLogo });
    }
    if (!!orgSmallLogo && !!orgSmallLogoName) {
      const updatedOrgSmallLogoUrl = createUrl(
        orgSmallLogo,
        SMALL_LOGO,
        orgCode
      );
      updatedUrl.push({
        type: SMALL_LOGO,
        url: updatedOrgSmallLogoUrl,
        img: orgSmallLogo,
      });
    }
    let fileResponse = '';
    let files = [];
    let filePath = [];
    updatedUrl.forEach(ele => {
      filePath.push(ele.url);
      files.push(ele.img);
    });
    if (!!updatedUrl && !isEmpty(updatedUrl)) {
      fileResponse = yield call(
        uploadMultipleFile,
        files,
        JSON.stringify(filePath)
      );
      if (fileResponse && fileResponse.status === 200) {
        isFileUploadErrorPresent = false;
        const { files: file_url } = fileResponse.data;
        file_url.forEach((ele, indx) => {
          updatedUrl[indx].url = ele;
        });
        let updatedFileArray = imageArray.map(function(o1) {
          return updatedUrl.find(function(o2) {
            return o1.type === o2.type;
          });
        });
        imageArray = updatedFileArray;
      } else {
        isFileUploadErrorPresent = true;
        yield put(organizationFileUploadError(true));
        yield put(errorHandler(fileResponse));
      }
    }
    if (!isFileUploadErrorPresent) {
      let response = yield axios.put(`/api/v1/organization`, {
        organization_id: orgId,
        client_name: orgName.trim(),
        theme: orgTheme,
        logo: imageArray[1]?.url
          ? imageArray[1]?.url
          : organizationDetails?.logo,
        abbreviation: orgShortName.trim(),
        logo_small: imageArray[2]?.url
          ? imageArray[2].url
          : organizationDetails?.logoSmall,
        logo_favicon: imageArray[0]?.url
          ? imageArray[0].url
          : organizationDetails?.logoFavicon,
        domain: orgUrl.trim(),
        support_email: email.trim(),
      });
      if (
        !!response?.status &&
        response.status === 200 &&
        !!response?.data?.success
      ) {
        yield put(storeOrganizationData(response.data));
        yield displaySuccessMessage(response.data.message);
      } else {
        yield put(setAddUpdateOrganizationLoader(false));
        yield displayErrorMessage(response.data.message);
      }
    }
  } catch (error) {
    if (!!error?.response && error.response.status === 401) {
yield displayErrorMessage(error.response.data.message)
      yield put(errorHandler(error));
    } else {
      yield put(errorHandler(error));
    }
  }
  yield put(setAddUpdateOrganizationLoader(false));
}

export function* updateOrganizationDataWatcher() {
  yield takeEvery(
    getOrganizationDetails.UPDATE_ORGANIZATION_DATA,
    updateOrganizationData
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchOrganizationListWatcher),
    fork(addNewOrganizationDataWatcher),
    fork(updateOrganizationDataWatcher),
  ]);
}
