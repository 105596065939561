// @ts-check

import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import CarrierStoreAnimation from '../../assets/images/carrier-store-animation.mp4';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import './agent-application-modal.scss';
import { connect } from 'react-redux';
import { setApplicationModal } from 'redux/actions/auth';
import { setCarrierFlowViewed } from 'redux/actions/dashboard';
import { fetchIncentiveEarning } from 'redux/actions/incentives';

const CarrierAgentApplicationModal = (props) => {
  const [isNextModal, setNextModal] = useState(props.isApplicationModal);
  const onCloseModal = () => {
    props.setApplicationModal(false);
    props.setCarrierFlowViewed();
    setNextModal(false);
  };
  const onClickNext = () => {
    setNextModal(false);
    props.fetchIncentiveEarning();
  };
  useEffect(() => {
    if (props.isApplicationModal) {
      setNextModal(true);
    }
  }, [props.isApplicationModal]);

  return (
    <Dialog
      open={props.isApplicationModal}
      onClose={onCloseModal}
      className="animation-modal-wrap"
    >
      <DialogContent className="animation-modal-content">
        <Typography>Thank You for Completing your Application! </Typography>

        <div className="animation-img-wrap">
          <ReactPlayer
            className="react-player fixed-bottom"
            url={CarrierStoreAnimation}
            width="100%"
            height="100%"
            controls={false}
            muted={true}
            playing={true}
            loop={true}
          />
        </div>
        {isNextModal ? (
          <>
            <h2>Request your appointments</h2>
            <Typography>
              Check out the <b>Carrier Store</b>, where you browse our list of
              world-class carriers. Request at least <b>3 new appointments</b>{' '}
              (or more if your want!) to unleash the full power of the Portal.
            </Typography>
          </>
        ) : (
          <>
            <h2>Earn up to ${props.fastStartEarning?.new} per bound policy</h2>
            <Typography>
              Also, you&apos;ve qualified in our Fast Start program (earn up to
              ${props.fastStartEarning?.new} per bound policy), and will go live
              once your first appointment is granted. Stay tuned!
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions className="animation-modal-footer">
        {isNextModal ? (
          <button onClick={onClickNext} className="round-primary-button">
            Next
          </button>
        ) : (
          <button onClick={onCloseModal} className="round-primary-button">
            let’s go!
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = ({ dashboard, incentive }) => {
  const { isApplicationModal } = dashboard;
  const { fastStartEarning } = incentive;

  return {
    isApplicationModal,
    fastStartEarning,
  };
};

export default connect(mapStateToProps, {
  setApplicationModal,
  setCarrierFlowViewed,
  fetchIncentiveEarning,
})(CarrierAgentApplicationModal);
