import * as types from '../../constants/action-types';

const initState = () => ({
  orgDetails: JSON.parse(localStorage.getItem('organizationDetails')),
  orgDetailsLoader: false,
  transitionLoginSuccess: 0,
  transitionGetAgentSuccess: 0,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_ORGANIZATION_DATA: {
      return { ...state, orgDetails: action.payload };
    }
    case types.ORGANIZATION_DATA_LOADER: {
      return { ...state, orgDetailsLoader: action.payload };
    }
    case types.SET_SIGNUP_TRANSITION_LOGIN_SUCCESS: {
      return {
        ...state,
        transitionLoginSuccess: action.payload,
      };
    }
    case types.SET_SIGNUP_TRANSITION_GET_AGENT_SUCCESS: {
      return {
        ...state,
        transitionGetAgentSuccess: action.payload,
      };
    }

    default:
      return state;
  }
};
