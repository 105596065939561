import { all } from 'redux-saga/effects';
import authSagas from './auth';
import preSignupSagas from './pre-sign-up';
import postSignupSagas from './post-sign-up';
// ------------ Producer/Agent-------------------
// Update Password
import updatePassword from './update-password';
// Dashboard
import agentDashboard from './dashboard';
// My Commission
import myCommission from './my-commission';
// My Account
import myAccount from './my-account';
// My Policies
import myPolicies from './my-policies';
// ----------------Admin-------------------------
// Admin - Agent Directory
import agentDetails from './agent-details';
import commissions from './commissions';
import adhocPayment from './adhoc-payment';
import errorDetails from './error';
// Carrier List
import carrierOnboardList from './carrier-on-board-list';
// Carrier Details
import carrierDetails from './carrier-details';
// Reports
import reports from './reports';
// ---------------Permission---------------------
import rolePermission from './role-permission';
import userPermission from './user-permission';

// ---------------Other--------------------------
import carrierList from './carrier-list';
import carrierAppointmentList from './carrier-appointment-list';
import carrierAppointmentSettings from './carrier-appointment-setting';
import carrierCategories from './carrier-categories';
import DocumentDownload from './document-download';
// ---------------Advance Search-----------------
import advanceSearch from './advance-search';
// ---------------Quote Connector----------------
import quoteConnector from './quote-connector';
import impersonate from './impersonate';
// ---------------Product----------------
import product from './product';
// ---------------Admin User----------------
import adminUsers from './admin-users';
// ---------------Incentive Banner----------------
import incentiveBanner from './incentive-banner';
//  -------------- LOA Filters ----------
import loaFilters from './loa-filters';
//  -------------- SignIn ----------
import organizationDetails from './organization-details';
// Organizations
import organizations from './organizations';
// -------------- Carrier Credentials ----------
import carrierCredentials from './carrier-credentials';
// -------------- Carrier Appointment Settings Admin ----------
import appointmentSettingsAdmin from './carrier-appointment-settings-admin';
// -------------- Appointment Requests Admin ----------
import appointmentRequests from './appointment-requests';

import classCodes from './class-codes';
// ---------------Bulk Appointments----------------
import bulkAppointments from './bulk-appointments';
// ---------------Agent Messaging----------------
import agentMessaging from './agent-messaging';
// ---------------Fast Start----------------
import fastStart from './fast-start';
// ----------------Q2 Incentive--------------
import q2Incentive from './q2-incentive';
// ----------------Leaderboard--------------
import leaderboard from './leaderboard';
// ----------------Q3/Q4 Incentive--------------
import incentive from './incentives';
// ----------------Producer Settings--------------
import producerSettings from './producer-settings';
// ----------------Refresh State--------------
import refreshState from './refresh-state';
import signIn from './auth.sign-in';

export default function* rootSaga() {
  yield all([
    authSagas(),
    producerSettings(),
    updatePassword(),
    errorDetails(),
    preSignupSagas(),
    postSignupSagas(),
    agentDashboard(),
    myCommission(),
    myAccount(),
    myPolicies(),
    agentDetails(),
    commissions(),
    adhocPayment(),
    carrierList(),
    DocumentDownload(),
    carrierOnboardList(),
    carrierDetails(),
    reports(),
    rolePermission(),
    userPermission(),
    advanceSearch(),
    quoteConnector(),
    impersonate(),
    product(),
    adminUsers(),
    incentiveBanner(),
    loaFilters(),
    organizationDetails(),
    organizations(),
    carrierCredentials(),
    carrierAppointmentSettings(),
    appointmentSettingsAdmin(),
    appointmentRequests(),
    carrierAppointmentList(),
    carrierCategories(),
    classCodes(),
    bulkAppointments(),
    agentMessaging(),
    fastStart(),
    q2Incentive(),
    leaderboard(),
    incentive(),
    refreshState(),
    signIn(),
  ]);
}
