import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { PORTAL } from 'constants/heap-events';
import { SIGNOUT_ROUTE } from 'constants/routes';
import { SIGNOUT_IS_EMAIL_VERIFY_SEARCH_PARAM } from 'constants/constant';
import { setEmailVerificationStartedCookie } from 'util/auth/email-verification';

const useStyles = makeStyles(() => {
  return {
    root: {
      borderRadius: 8,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 16,
      padding: 32,
    },
    heading: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '140%',
      textAlign: 'center',
      color: '#525252',
      margin: 0,
    },
    subheading: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: '#525252',
      margin: 0,
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #E0E0E0',
      padding: '24px',
    },
    cta: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '12px 40px',
      gap: '6px',
      width: '160px',
      height: '40px',
      background: '#FF8B1F',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
      border: 'none',
      color: 'white',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: '#333333',
      },
    },
  };
});

const MODAL_TYPE = 'Sign-up authentication';

/**
 * Renders a modal for verifying email address.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Flag indicating whether the modal is open or not.
 * @returns {JSX.Element} The rendered VerifyEmailModal component.
 */
export default function VerifyEmailModal({ isOpen }) {
  const history = useHistory();
  const [didTrackModalSeen, setDidTrackModalSeen] = useState(false);
  const classes = useStyles();

  useEffect(
    function trackModalSeen() {
      if (isOpen && !didTrackModalSeen) {
        window.heap?.track(PORTAL.VIEW_MODAL, {
          Type: MODAL_TYPE,
        });
        setDidTrackModalSeen(true);
      }
    },
    [isOpen, didTrackModalSeen]
  );

  const handleCtaClick = () => {
    window.heap?.track(PORTAL.MODAL_CLICK_MAIN_CTA, {
      Type: MODAL_TYPE,
    });
    setEmailVerificationStartedCookie();
    history.push(
      `${SIGNOUT_ROUTE}?${SIGNOUT_IS_EMAIL_VERIFY_SEARCH_PARAM}=true`
    );
  };

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="xs"
      open={isOpen}
    >
      <DialogContent className={classes.content}>
        <NotificationIllustration />
        <h2 className={classes.heading}>Verify your email address</h2>
        <Typography className={classes.subheading}>
          To begin requesting appointments, please confirm your email address
        </Typography>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <button onClick={handleCtaClick} className={classes.cta}>
          Verify Email
        </button>
      </DialogActions>
    </Dialog>
  );
}

function NotificationIllustration() {
  return (
    <svg
      width="128"
      height="128"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.4395 21.3799L86.5994 33.1799V40.0799V46.9899L40.4395 58.7799"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.4397 40.08V20.38C40.4397 19.07 39.3697 18 38.0597 18C36.7497 18 35.6797 19.07 35.6797 20.38V59.79C35.6797 61.1 36.7497 62.17 38.0597 62.17C39.3697 62.17 40.4397 61.1 40.4397 59.79V52"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.5996 33.1798L95.0596 31.8398"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.0596 48.2101L86.5996 46.8701"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.0605 47.5697C95.0605 49.0897 96.2905 50.3297 97.8205 50.3297C99.3405 50.3297 100.581 49.0997 100.581 47.5697V32.4797C100.581 30.9597 99.3505 29.7197 97.8205 29.7197C96.3005 29.7197 95.0605 30.9497 95.0605 32.4797V36.0897"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.75 49.6299V58.3199C76.75 59.8599 75.5 61.1199 73.95 61.1199H63.26C61.72 61.1199 60.46 59.8699 60.46 58.3199V54.0199"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.09 40.9697H12"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0295 31.9504L17.7695 22.6904"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0295 49.5303L17.7695 58.7803"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.4301 86.2502H56.7701C54.4801 86.2502 52.6201 84.3902 52.6201 82.1002C52.6201 79.8102 54.4801 77.9502 56.7701 77.9502H73.6701"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.7502 94.5504H59.3102C57.0202 94.5504 55.1602 92.6904 55.1602 90.4004"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.7502 102.86H62.0602C59.7702 102.86 57.9102 101 57.9102 98.71"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.58 104.42C92.9297 104.26 91.4797 104.88 91.4797 104.88C91.4797 104.88 89.5697 105.25 87.1497 106.03C79.6597 108.44 74.3697 109.75 66.4997 109.95L64.5797 109.98C62.2897 109.98 60.4297 108.12 60.4297 105.83"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.6699 68.4098C74.0799 68.3698 74.4899 68.3298 74.9099 68.2698C78.5899 67.7798 82.3199 68.6498 85.3799 70.7598C87.3199 72.0998 89.5199 73.6998 91.5399 75.3698C91.5399 75.3698 95.6099 79.4898 100.58 79.4898"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.4297 105.83V102.86"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.9102 98.7096V94.3096"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108 74.7998H102.93C101.63 74.7998 100.58 75.8498 100.58 77.1498V106.77C100.58 108.07 101.63 109.12 102.93 109.12H108"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.1602 90.3997V85.9297"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.9902 77.9401V61.1201"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M73.2402 61.1201V77.9401"
        stroke="#495165"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.1607 40.0804V36.6304L52.7207 29.1104L53.4807 30.1804C57.7507 36.2004 58.1907 44.1304 54.6007 50.5804L82.1607 43.5404V40.0904V40.0804Z"
        fill="#FF8125"
      />
    </svg>
  );
}
