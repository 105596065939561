// @ts-check

import { getAuth0SpaClient, getULTemplateParams } from './auth0-spa-client';

/**
 * @param {Object} options - The options for issuing the login email.
 * @param {string} options.email - The email address to send the login email to.
 * @param {string} options.producerCode - The producer code.
 */
export async function issueAuth0SpaPasswordlessLoginEmail({
  email,
  producerCode,
}) {
  await getAuth0SpaClient().loginWithRedirect({
    authorizationParams: {
      connection: 'email',
      login_hint: email,
      ...getULTemplateParams({
        producerCode,
      }),
    },
  });
}

/**
 * @param {Object} options - The options for issuing the login email.
 * @param {string} options.phone - The email address to send the login email to.
 * @param {string} options.producerCode - The producer code.
 */
export async function issueAuth0SpaPasswordlessLoginPhone({
  phone,
  producerCode,
}) {
  await getAuth0SpaClient().loginWithRedirect({
    authorizationParams: {
      connection: 'sms',
      login_hint: phone,
      ...getULTemplateParams({
        producerCode,
      }),
    },
  });
}
