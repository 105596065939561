// @ts-check

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Main } from 'firstconnect-portal-frontend';
import {
  carrierStoreThankYouModalClose,
  updateCarrierAppointmentRequestCount,
} from 'redux/actions/auth';
import {
  ADMIN,
  CARRIER_APPOINTMENT_REQ_COUNT,
  MESSAGE,
  NEW_APP_ROUTES,
} from 'constants/constant';
import VerifyEmailModal from 'components/carrier-store/verify-email-modal';
import {
  canSeeEmailVerificationModalOnPageLoad,
  isUserSubProducer,
  shouldRedirectToCarrierStore,
} from 'util/extra';
import { getIsNewSignUpFlowEnabled } from 'util/auth/get-is-new-sign-up-flow-enabled';
import { isIOS, isMobile as isMobileDevice } from 'react-device-detect';
import { navigateToNewAppRoute } from 'util/navigate-to-new-app-route';
import { getAuth0SpaToken } from 'util/auth/auth0.utils';
import DancingDots from 'components/dancing-dots';
import EmailVerificationSuccessfulModal from 'containers/email-verification-successful-modal/email-verification-successful-modal';
import { carrierNameToIdMapping } from 'constants/carrier-name-to-id-mapping';
import ThankYouModal from 'components/carrier-store/thank-you-modal';
import CarrierAgentApplicationModal from 'components/agent-application-modal';

// These consts are present in Legacy UI and Carrier Store package
// When editing, apply changes to both repos
const WINDOW_MESSAGE_SHOW_VERIFY_EMAIL_MODAL = 'SHOW_VERIFY_EMAIL_MODAL';
const WINDOW_MESSAGE_UPDATE_REQUEST_COUNT = 'UPDATE_REQUEST_COUNT';

const CarrierStore = (props) => {
  const [isVerifyEmailModalOpen, setIsVerifyEmailModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const carrierStoreRef = useRef(null);
  const isNewSignUpFlowEnabled = getIsNewSignUpFlowEnabled();
  const isMobile = isMobileDevice || isIOS;

  /*
   * Logic for carrier store intro modal:
   */
  let isImpersonateModeOn = !!localStorage.getItem('isImpersonatedDataPresent')
    ? JSON.parse(localStorage.getItem('isImpersonatedDataPresent') ?? 'false')
    : false;
  let roleCategory = localStorage.getItem('role_category');
  const isSubProducer =
    isUserSubProducer(localStorage.getItem('signupFlow')) ||
    isUserSubProducer(localStorage.getItem('impersonateSignupFlow'));

  const shouldShowCarrierStoreIntroModal =
    (isImpersonateModeOn || roleCategory !== ADMIN) &&
    !isSubProducer &&
    !isMobile &&
    !isIOS &&
    shouldRedirectToCarrierStore(
      props.carrierStoreAgentApplicationRestrictionEnabled,
      props.carrierAppointmentRequestCount,
      props.enableMenuItem,
      props.isNewCarrierStoreEnabled
    );

  /**
   * Fetches the auth token from Auth0 SPA before loading the view.
   * It's necessary since there are calls made inside Carrier Store that can happen before
   * the token is synced between Auth0 and localStorage.
   * @TODO Delete when Carrier Store refactor is complete https://myhippo.atlassian.net/browse/FRC-4564
   */
  useEffect(function loadAuthToken() {
    const fetchToken = async () => await getAuth0SpaToken();
    fetchToken().then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(
    function handleVerificationModalOnPageLoad() {
      if (
        // isCarrierStoreIntroModalOpen handles case for user with 5 or less carriers available
        // isMobile hansles case for mobile devices
        (!props.isCarrierStoreIntroModalOpen || isMobile) &&
        canSeeEmailVerificationModalOnPageLoad(
          props.carrierStoreAgentApplicationRestrictionEnabled,
          props.carrierAppointmentRequestCount,
          props.enableMenuItem
        )
      ) {
        showVerifyEmailModalIfUnverified();
      }
    },
    [
      props.isUserEmailVerified,
      props.isCarrierStoreIntroModalOpen,
      props.carrierStoreAgentApplicationRestrictionEnabled,
      props.carrierAppointmentRequestCount,
      props.enableMenuItem,
    ]
  );

  useEffect(function listenForCarrierStoreMessages() {
    window.addEventListener(MESSAGE, handleCarrierStoreMessage);
    return () => window.removeEventListener(MESSAGE, handleCarrierStoreMessage);
  }, []);

  const handleCarrierStoreMessage = (event) => {
    switch (event.data?.type) {
      case WINDOW_MESSAGE_SHOW_VERIFY_EMAIL_MODAL:
        showVerifyEmailModalIfUnverified();
        break;
      case WINDOW_MESSAGE_UPDATE_REQUEST_COUNT:
        handleUpdateDetails(event);
        break;
      default:
        break;
    }
  };

  const showVerifyEmailModalIfUnverified = () => {
    if (!props.isUserEmailVerified && isNewSignUpFlowEnabled) {
      setIsVerifyEmailModalOpen(true);
    }
  };

  const handleUpdateDetails = (event) => {
    const carrierAppointmentRequestCount = localStorage.getItem(
      CARRIER_APPOINTMENT_REQ_COUNT
    );
    if (!!event?.data?.carrierStoreDetails?.carrierAppointmenRequestSuccess) {
      let count = carrierAppointmentRequestCount
        ? JSON.parse(carrierAppointmentRequestCount)
        : 0;
      count++;
      props.updateCarrierAppointmentRequestCount(count);
      localStorage.setItem(CARRIER_APPOINTMENT_REQ_COUNT, count);
      if (
        count === 3 &&
        props?.carrierStoreAgentApplicationRestrictionEnabled
      ) {
        props.carrierStoreThankYouModalClose(true);
      }
    }
  };

  /**
   * This function preserves query params between old and new carrier store,
   * when a user is trying to access a carrier via url.
   * @returns {string | undefined} Query params for the new carrier store or undefined
   */
  const getNewCarrierStoreQueryParams = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const carrierName = queryParams.get('carrier_name');

    if (!carrierName) {
      return;
    }

    const carrier = carrierNameToIdMapping.find(
      ({ carrier_code, display_name }) =>
        display_name.toLowerCase() === carrierName.toLowerCase() ||
        carrier_code.toLowerCase() === carrierName.toLowerCase()
    );

    if (!carrier) {
      return;
    }

    const params = new URLSearchParams();
    params.set('carrier_id', carrier.id.toString());
    params.set('source', 'Carrier Store Card');
    return params.toString();
  };

  if (props.isNewCarrierStoreEnabled === null) {
    return (
      <div style={{ height: '100%' }} className="loader-view">
        <DancingDots fullpageWrapper />
      </div>
    );
  }

  if (props.isNewCarrierStoreEnabled) {
    const queryParams = getNewCarrierStoreQueryParams();
    navigateToNewAppRoute(NEW_APP_ROUTES.CARRIER_STORE, queryParams);
    return null;
  }

  if (isLoading) {
    return (
      <div style={{ height: '100%' }} className="loader-view">
        <DancingDots fullpageWrapper />
      </div>
    );
  }

  return (
    <div>
      <Main ref={carrierStoreRef} />
      {shouldShowCarrierStoreIntroModal && <CarrierAgentApplicationModal />}
      <ThankYouModal />
      <VerifyEmailModal isOpen={isVerifyEmailModalOpen} />
      <EmailVerificationSuccessfulModal />
    </div>
  );
};

const mapStateToProps = ({ auth, dashboard, producerSettings }) => {
  const {
    carrierAppointmentRequestCount,
    carrierStoreAgentApplicationRestrictionEnabled,
    enableMenuItem,
    isUserEmailVerified,
  } = auth;

  const { isApplicationModal } = dashboard;
  const { isNewCarrierStoreEnabled } = producerSettings;

  return {
    carrierStoreAgentApplicationRestrictionEnabled,
    carrierAppointmentRequestCount,
    enableMenuItem,
    isUserEmailVerified,
    isNewCarrierStoreEnabled,
    isCarrierStoreIntroModalOpen: isApplicationModal,
  };
};

CarrierStore.propTypes = {
  isUserEmailVerified: PropTypes.bool.isRequired,
  isCarrierStoreIntroModalOpen: PropTypes.bool.isRequired,
  carrierStoreAgentApplicationRestrictionEnabled: PropTypes.bool.isRequired,
  carrierAppointmentRequestCount: PropTypes.number.isRequired,
  enableMenuItem: PropTypes.func.isRequired,
  carrierStoreThankYouModalClose: PropTypes.func.isRequired,
  updateCarrierAppointmentRequestCount: PropTypes.func.isRequired,
  isNewCarrierStoreEnabled: PropTypes.bool,
};

export default connect(mapStateToProps, {
  carrierStoreThankYouModalClose,
  updateCarrierAppointmentRequestCount,
})(CarrierStore);
