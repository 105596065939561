import * as types from '../../constants/action-types';
import { findIndex } from 'lodash';
const initState = () => ({
  organizationsList: [],
  organizationsLoader: false,
  addUpdateOrgLoader: false,
  isOrganizationFileUploadError: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.ORGANIZATION_LOADER: {
      return { ...state, organizationsLoader: action.payload };
    }
    case types.STORE_ORGANIZATION_LIST: {
      return { ...state, organizationsList: action.payload };
    }
    case types.ADD_UPDATE_ORGANIZATION_LOADER: {
      return { ...state, addUpdateOrgLoader: action.payload };
    }
    case types.STORE_NEW_ORGANIZATION_DATA: {
      const { organization, success, message } = action.payload;
      return {
        ...state,
        organizationsList: [organization, ...state.organizationsList],
        success: success,
        message: message,
      };
    }
    case types.CLEAR_ORGANIZATION_DATA: {
      return { ...state, success: false, message: '' };
    }
    case types.STORE_ORGANIZATION_DATA: {
      const { organization, success, message } = action.payload;
      let tempOrganizationsList = [...state.organizationsList];
      const index = findIndex(tempOrganizationsList, item => {
        return item.organization_id === organization.organization_id;
      });
      tempOrganizationsList[index].abbreviation = organization.abbreviation;
      tempOrganizationsList[index].client_name = organization.client_name;
      tempOrganizationsList[index].domain = organization.domain;
      tempOrganizationsList[index].logo = organization.logo;
      tempOrganizationsList[index].logo_favicon = organization.logo_favicon;
      tempOrganizationsList[index].logo_small = organization.logo_small;
      tempOrganizationsList[index].organization_code =
        organization.organization_code;
      tempOrganizationsList[index].organization_id =
        organization.organization_id;
      tempOrganizationsList[index].theme = organization.theme;
      tempOrganizationsList[index].support_email = organization.support_email;
      return {
        ...state,
        organizationsList: [...tempOrganizationsList],
        success: success,
        message: message,
      };
    }
    case types.ORGANIZATION_FILE_UPLOAD_ERROR: {
      return {
        ...state,
        isOrganizationFileUploadError: action.payload,
      };
    }
    default:
      return state;
  }
};
