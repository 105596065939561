// @ts-check
// import { getAuth0SpaClient } from './auth0-spa-client';
// import { getIsGradualRollout } from './auth0.utils';

/**
 * Retrieves the authentication status for gradual rollout.
 * @returns {Promise<boolean>} A promise that resolves to the authentication status (true/false).
 */
export async function getIsAutnenticatedForGradualRollout() {
  // const isGradialRollout = getIsGradualRollout();
  return Boolean(localStorage.getItem('Authorization'));

  // if (isGradialRollout) {
  //   return await getAuth0SpaClient().isAuthenticated();
  // } else {
  //   return Boolean(localStorage.getItem('Authorization'));
  // }
}
