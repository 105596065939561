import * as types from '../../constants/action-types';

const initState = () => ({
  email: null,
  // Producer code is randomized when non-existing email is entered
  // Value is unsafe - meaning it shouldn't be used for purposes like tracking
  unsafeProducerCode: null,
  isTextSigningAvailable: false,
  isVerified: false,
});

export default (state = initState(), action) => {
  switch (action.type) {
    case types.SET_SIGN_IN_COHORT_DATA: {
      return {
        ...state,
        email: action.payload.email,
        isVerified: action.payload.isVerified,
        unsafeProducerCode: action.payload.unsafeProducerCode,
        isTextSigningAvailable: action.payload.isTextSigningAvailable,
      };
    }

    default:
      return state;
  }
};
