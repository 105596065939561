import * as organizationType from 'constants/action-types';

export const setOrganizationLoader = flag => {
  return {
    type: organizationType.ORGANIZATION_LOADER,
    payload: flag,
  };
};

export const fetchOrganizationList = () => {
  return {
    type: organizationType.FETCH_ORGANIZATION_LIST,
  };
};

export const storeOrganizationList = data => {
  return {
    type: organizationType.STORE_ORGANIZATION_LIST,
    payload: data,
  };
};

export const setAddUpdateOrganizationLoader = flag => {
  return {
    type: organizationType.ADD_UPDATE_ORGANIZATION_LOADER,
    payload: flag,
  };
};

export const addNewOrganization = payload => {
  return {
    type: organizationType.ADD_NEW_ORGANIZATION,
    payload,
  };
};

export const storeNewOrganizationData = data => {
  return {
    type: organizationType.STORE_NEW_ORGANIZATION_DATA,
    payload: data,
  };
};

export const updateOrganizationData = data => {
  return {
    type: organizationType.UPDATE_ORGANIZATION_DATA,
    payload: data,
  };
};

export const storeOrganizationData = data => {
  return {
    type: organizationType.STORE_ORGANIZATION_DATA,
    payload: data,
  };
};

export const clearOrganizationData = () => {
  return {
    type: organizationType.CLEAR_ORGANIZATION_DATA,
  };
};

export const organizationFileUploadError = payload => {
  return {
    type: organizationType.ORGANIZATION_FILE_UPLOAD_ERROR,
    payload,
  };
};
