// @ts-check

import {
  INCORRECT_EMAIL,
  INCORRECT_PHONE_NUMBER,
  REQUIRED,
} from 'constants/constant';
import { email_regex, phone_regex } from 'util/sign-up-process';

/**
 * Default email key for passwordless sign-in.
 * @type {string}
 */
export const PWDLESS_SIGNIN_DEFAULT_EMAIL_KEY =
  'passwordlessSignInDefaultEmail';

/**
 * Default phone key for passwordless sign-in.
 * @type {string}
 */
export const PWDLESS_SIGNIN_DEFAULT_PHONE_KEY =
  'passwordlessSignInDefaultPhone';


/**
 * Default tab in passwordless form.
 * @type {string}
 */
export const PWDLESS_SIGNIN_DEFAUlT_SELECTED_TAB_KEY = 'passwordlessSignInDefaultSelectedTab';


/**
 * Get the local storage key based on the type.
 * @param {'email' | 'phone'} type - The type of input
 * @returns {string} The local storage key.
 */
const getLocalStorageKey = (type) => {
  switch (type) {
    case 'email': {
      return PWDLESS_SIGNIN_DEFAULT_EMAIL_KEY;
    }
    case 'phone': {
      return PWDLESS_SIGNIN_DEFAULT_PHONE_KEY;
    }
    default: {
      return '';
    }
  }
};

/**
 * Get the default passwordless sign-in input value based on the type.
 * @param {'email' | 'phone'} type - The type of input
 * @returns {string} The default input value.
 */
export const getDefaultPasswordlessSignInputVal = (type) => {
  try {
    const val = localStorage.getItem(getLocalStorageKey(type));
    if (val && typeof val === 'string') {
      return val;
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error(`Error parsing default sign in ${type} input value`);
  }
  return '';
};

export const getDefultPasswordlessFormTab = () => {
  const val = localStorage.getItem(PWDLESS_SIGNIN_DEFAUlT_SELECTED_TAB_KEY) ?? '';
  if (['email', 'phone'].includes(val)) {
    return val;
  }
  return 'email';
}

/**
 * Store the default passwordless sign-in input value based on the type.
 * @param {string} val - The input value to store.
 * @param {'email' | 'phone'} type - The type of input
 */
export const storeDefaultPasswordlessSignInputVal = (val, type) => {
  localStorage.setItem(getLocalStorageKey(type), val);
};

/**
 * Validate an email address.
 * @param {string} email - The email address to validate.
 * @returns {string | undefined} The validation error message, if any.
 */
export const validateEmail = (email) => {
  if (email.length <= 0) {
    return REQUIRED;
  }
  if (!email_regex.test(email)) {
    return INCORRECT_EMAIL;
  }
};

/**
 * Validate a phone number.
 * @param {string} phoneNumber - The phone number to validate.
 * @returns {string | undefined} The validation error message, if any.
 */
export const validatePhoneNumber = (phoneNumber) => {
  if (phoneNumber.length <= 0) {
    return REQUIRED;
  }
  if (!phone_regex.test(phoneNumber)) {
    return INCORRECT_PHONE_NUMBER;
  }
};
