// @ts-check

import { getAuth0SpaToken, getIsGradualRollout, logAuthError } from './auth0.utils';

/**
 * Retrieves the token for gradual rollout.
 * For Auth0 Universal Login implementation, @auth0/auth0-spa-js must be the source of truth.
 * @returns {Promise<string | null>} A promise that resolves to the JWT auth token.
 */
export async function getTokenForGradualRollout() {
  const isGradualRollout = getIsGradualRollout();

  if (!isGradualRollout) {
    return localStorage.getItem('Authorization');
  }

  const auth0SdkToken = await getAuth0SpaToken();
  if (auth0SdkToken) {
    return auth0SdkToken;
  }

  const localStorageToken = localStorage.getItem('Authorization');
  if (localStorageToken) {
    logAuthError('Error retrieving token from Auth0. Defaulted to localStorage.');
    return localStorageToken;
  }

  return null;
}
