export const INDIGO = 'indigo';
export const CYAN = 'cyan';
export const AMBER = 'amber';
export const DEEP_ORANGE = 'deep-orange';
export const PINK = 'pink';
export const BLUE = 'blue';
export const DEEP_PURPLE = 'deep-purple';
export const GREEN = 'green';
export const DARK_INDIGO = 'dark-indigo';
export const DARK_CYAN = 'dark-cyan';
export const DARK_AMBER = 'dark-amber';
export const DARK_DEEP_ORANGE = 'dark-deep-orange';
export const DARK_PINK = 'dark-pink';
export const DARK_BLUE = 'dark-blue';
export const DARK_DEEP_PURPLE = 'dark-deep-purple';
export const DARK_GREEN = 'dark-green';
export const DARK_THEME_COLOR = 'dark-theme';
export const THEMES_IMAGES = {
  'sky-blue': 'sky-blue',
  'navy-blue': 'navy-blue',
  'purplish-blue': 'purplish-blue',
};

