import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { history, store } from './redux/store';
import App from './containers/app';
import ErrorBoundary from './components/error-boundary';

const MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ErrorBoundary>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ErrorBoundary>
    </ConnectedRouter>
  </Provider>
);

export default MainApp;
