// @ts-check
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  clearEmailVerificationStartedCookie,
  hasFinishedEmailVerification,
} from 'util/auth/email-verification';

const useStyles = makeStyles(() => {
  return {
    root: {
      borderRadius: 8,
    },
    illustration: {
      alignSelf: 'center',
      margin: '32px auto 16px auto',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 16,
      padding: 32,
      paddingTop: 0,
    },
    heading: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '140%',
      textAlign: 'center',
      color: '#525252',
      margin: 0,
    },
    subheading: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '140%',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      color: '#525252',
      margin: 0,
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      borderTop: '1px solid #E0E0E0',
      padding: '24px',
    },
    cta: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '12px 40px',
      gap: '6px',
      width: '240px',
      height: '40px',
      background: '#FF8B1F',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
      borderRadius: '20px',
      border: 'none',
      color: 'white',
      textTransform: 'uppercase',
      whiteSpace: 'nowrap',
      transition: 'background-color 0.3s ease',
      '&:hover': {
        backgroundColor: '#333333',
      },
    },
  };
});

export default function EmailVerificationSuccessfulModal() {
  const classes = useStyles();
  const isUserEmailVerified = useSelector(
    (state) => state.auth.isUserEmailVerified
  );
  const [isOpen, setIsOpen] = useState(
    hasFinishedEmailVerification(isUserEmailVerified)
  );

  useEffect(function markModalViewedOnMount() {
    clearEmailVerificationStartedCookie();
  }, []);

  const handleCtaClick = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      className={classes.root}
      fullWidth={true}
      maxWidth="xs"
      open={isOpen}
    >
      <div className={classes.illustration}>
        <svg
          width="72"
          height="72"
          viewBox="0 0 72 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="72" height="72" rx="36" fill="#25CF41" />
          <path
            d="M32.9991 40.7582L46.7871 26.9688L48.9096 29.0898L32.9991 45.0002L23.4531 35.4542L25.5741 33.3333L32.9991 40.7582Z"
            fill="white"
          />
        </svg>
      </div>

      <DialogContent className={classes.content}>
        <h2 className={classes.heading}>Email Verified</h2>
        <Typography className={classes.subheading}>
          Continue your First Connect journey by requesting your appointments!
        </Typography>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <button onClick={handleCtaClick} className={classes.cta}>
          Request Appointments
        </button>
      </DialogActions>
    </Dialog>
  );
}
