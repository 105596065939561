import {
  all,
  call,
  cancel,
  cancelled,
  fork,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { get, identity, isEmpty, isUndefined, map, pickBy } from 'lodash';
import axios from 'util/api';
import * as postSignupType from 'constants/action-types';
import * as postSignupActions from '../actions/post-sign-up';
import {
  setApplicationModal,
  setAuthInfo,
  setAuthUserLastName,
  setAuthUserName,
  setDashboardView,
  updateCarrierAppointmentRequestCount,
} from '../actions/auth';
import { errorHandler } from '../actions/error';
import {
  setConsentTextFlag,
  updateAgentNameInSignUpProcess,
} from '../actions/dashboard';
import { displaySuccessMessage } from 'util/user-feedback';
import {
  AGENCY_PROFILE,
  AGREEMENT_DATA,
  ALTERNATE_SUB_PRODUCER,
  BANK_INFORMATION_KEY,
  BUSINESS,
  CARRIER_APPOINTMENT_REQ_COUNT,
  ENO,
  FIRM,
  INDIVIDUAL,
  LICENSE_AND_ENO,
  NO,
  NPN_CALL_SUCCESS,
  PHONE_ALREADY_EXIST,
  PRODUCER_KEY,
  SHOW_CONSENT_MODAL,
  SUB_PRODUCER_KEY,
  TAX_KEY,
  YES,
} from 'constants/constant';
import { APPLICATION } from 'constants/heap-events';
import { uploadFile, uploadMultipleFile } from '../../util/extra';
import { setSignupTransGetAgentSuccess } from '../actions/organization-details';
import config from 'config';
import { saveState } from 'redux/persist';
import { duplicatePhoneNumber } from 'redux/actions/my-account';
const createUrl = (fileData, folder_name, type) => {
  const file = fileData[0];
  const name = file.name;
  if (type === ENO) {
    return `${config.PRODUCER_UPLOAD_FOLDER}/${folder_name}/${name}`;
  } else {
    return `${config.PRODUCER_UPLOAD_FOLDER}/${folder_name}/licenses/${name}`;
  }
};

const getValidData = (data, type) => {
  return !isEmpty(get(data, type, '')) ? data[type] : '';
};

function* stepDataFetch({ payload }) {
  yield put(setSignupTransGetAgentSuccess(0));
  try {
    yield put(postSignupActions.stepLoaderShow());
    const response = yield axios.get(
      `api/v1/producer/get/agent/${payload.producer_id}`
    );
    if (response.data) {
      const {
        producer_code,
        agencyProfile,
        tax,
        licenseAndEnO,
        bankInformation,
        has_accepted_agreement,
        uploadedAgreement,
      } = response.data.producers;
      const details = {
        producer_code,
        agencyProfile,
        tax,
        licenseAndEnO,
        bankInformation,
        has_accepted_agreement,
        uploadedAgreement,
      };
      yield put(setSignupTransGetAgentSuccess(2));
      // skips falsey values.
      const data = pickBy(details, identity);
      yield put(postSignupActions.stepDataFetchSuccess(data));
    } else {
      yield put(setSignupTransGetAgentSuccess(1));
      yield put(postSignupActions.stepDataFetchSuccess({}));
    }
  } catch (error) {
    yield put(setSignupTransGetAgentSuccess(1));
    yield put(postSignupActions.stepDataFetchFail());
    yield put(errorHandler(error));
  }

  yield put(postSignupActions.stepLoaderHide());
}

export function* stepDataFetchWatcher() {
  yield takeEvery(postSignupType.STEP_DATA_FETCH, stepDataFetch);
}

export function* getAgent({ payload: { producer_id } }) {
  try {
    yield put(postSignupActions.getAgentStarted());
    const response = yield axios.get(
      `api/v1/producer/get/agent/${producer_id}`
    );
    yield put(
      postSignupActions.getAgentSuccess({
        payload: {
          ...response.data.producers,
        },
      })
    );
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* getAgentWatcher() {
  yield takeEvery(postSignupType.FETCH_AGENT, getAgent);
}

export function* stepSubmit({ payload }) {
  const {
    stepId,
    entity,
    final,
    data,
    signupFlow,
    tax,
    signUpComplete,
    completedSignupStep,
  } = payload;
  const reduxState = yield select();
  const { postSignup, auth, impersonate } = reduxState;
  const { modulePermissionsData } = reduxState.modulePermissions;
  const { 'PRODUCTION-ACCESS': PRODUCTION_ACCESS } =
    !!modulePermissionsData &&
    !!modulePermissionsData['DASHBOARD'] &&
    modulePermissionsData['DASHBOARD'];
  yield put(duplicatePhoneNumber(false));
  try {
    let producerUpdatedData = null;
    let isErrorPresent = false;
    yield put(updateCarrierAppointmentRequestCount(0));
    localStorage.setItem(CARRIER_APPOINTMENT_REQ_COUNT, 0);
    localStorage.setItem('shouldShowBanner', false);
    localStorage.setItem('showFastStartModal', false);
    yield put(postSignupActions.submitLoaderShow());
    yield put(setApplicationModal(true));
    if (get(data, 'multipleFile', false)) {
      let multipleUrl = [];
      const folder_name = reduxState.postSignup.producer_code;
      let typeOfFile = {
        file: ENO,
        individualFile: INDIVIDUAL,
        businessFile: BUSINESS,
      };
      map(typeOfFile, (value, key) => {
        if (data[key]?.[0]?.name) {
          let updatedUrl = createUrl(data[key], folder_name, value);
          multipleUrl.push({
            url: updatedUrl,
            type: value,
          });
        }
      });
      if (!isEmpty(multipleUrl)) {
        let dataEntity = null;
        let files = [];
        let filePath = [];
        for (let item of multipleUrl) {
          dataEntity =
            item.type === ENO
              ? 'file'
              : item.type === INDIVIDUAL
              ? 'individualFile'
              : item.type === BUSINESS
              ? 'businessFile'
              : '';
          if (dataEntity !== 'file' && !isEmpty(data[dataEntity])) {
            files.push(data[dataEntity]);
            filePath.push(item.url);
          }
        }
        const responseUpdatedFile = yield call(
          uploadMultipleFile,
          files,
          JSON.stringify(filePath)
        );
        if (responseUpdatedFile && responseUpdatedFile.status === 200) {
          const { files: file_url, file_name } = responseUpdatedFile.data;
          for (let item of multipleUrl) {
            let individualLicenseUrl = '';
            let businessLicenseUrl = '';
            if (item.type === INDIVIDUAL) {
              individualLicenseUrl = file_url[0];
            }
            if (item.type === BUSINESS) {
              businessLicenseUrl =
                !isEmpty(multipleUrl) && multipleUrl?.length === 1
                  ? file_url[0]
                  : file_url[1];
            }
            if (item.type !== ENO) {
              yield put(
                postSignupActions.setLicenseUrl({
                  individualLicense: individualLicenseUrl,
                  businessLicense: businessLicenseUrl,
                  entity: entity,
                })
              );
              if (!isEmpty(individualLicenseUrl)) {
                data.individualLicenseUrl = individualLicenseUrl;
              }
              if (!isEmpty(businessLicenseUrl)) {
                data.businessLicenseUrl = businessLicenseUrl;
              }
            }
          }
          yield put(
            postSignupActions.filesUploadSuccess(file_url, file_name, entity)
          );
          isErrorPresent = false;
          yield put(postSignupActions.setFileUploadError(false));
        } else {
          isErrorPresent = true;
          yield put(postSignupActions.setFileUploadError(true));
          yield put(errorHandler(responseUpdatedFile));
          yield put(postSignupActions.stepSubmitFail());
        }
      }
    } else {
      if (data.file?.[0]?.name) {
        const file = data.file[0];
        const name = file.name;
        const folder_name = !!reduxState.postSignup.producer_code
          ? reduxState.postSignup.producer_code
          : localStorage.getItem('producer_code');
        const fileURL = `${config.PRODUCER_UPLOAD_FOLDER}/${folder_name}/${name}`;

        const result = yield call(uploadFile, file, fileURL);
        if (result && result.status === 200) {
          const { file: fileURLData } = result.data;
          let updateEntity = entity === 'licenseAndEnO' ? 'e&o' : '';

          const responseFile = yield axios.post(
            '/api/v1/producer/update-file',
            {
              producer_id: !!reduxState.auth.producer_id
                ? reduxState.auth.producer_id
                : localStorage.getItem('producer_id'),
              file_path: fileURLData,
              file_category: updateEntity,
            }
          );
          if (responseFile) {
            const { file_url, file_name } = responseFile.data;
            yield put(
              postSignupActions.filesUploadSuccess(file_url, file_name, entity)
            );

            data.fileName = file_name;
            data.fileurl = file_url;
          }
          yield put(postSignupActions.setFileUploadError(false));
        } else {
          isErrorPresent = true;
          yield put(errorHandler(result));
          yield put(postSignupActions.setFileUploadError(true));
          yield put(postSignupActions.stepSubmitFail());
        }
      }
    }

    if (!isErrorPresent) {
      if (
        !!signupFlow &&
        signupFlow === SUB_PRODUCER_KEY &&
        !!entity &&
        entity === AGENCY_PROFILE
      ) {
        producerUpdatedData = yield axios.post(
          '/api/v1/producer/update-producer',
          {
            data: {
              [entity]: { ...data, file: '' },
              [TAX_KEY]: { ...tax },
              stepId: stepId,
              signUpComplete: !!signUpComplete ? true : false,
              completedSignupStep,
            },
          }
        );
      } else {
        producerUpdatedData = yield axios.post(
          '/api/v1/producer/update-producer',
          {
            data: {
              [entity]: { ...data, file: '' },
              stepId: stepId,
              signUpComplete: !!signUpComplete ? true : false,
              completedSignupStep,
            },
          }
        );
        if (!!entity && entity === AGENCY_PROFILE) {
          localStorage.setItem('user_id', data.firstName);
          localStorage.setItem('user_last_name', data.lastName);
          localStorage.setItem('principleFirstName', data.firstName);
          localStorage.setItem('principleLastName', data.lastName);
        }
      }
      if (
        !!entity &&
        entity === AGENCY_PROFILE &&
        !!postSignup?.agencyProfile &&
        !!postSignup?.licenseAndEnO &&
        !!data?.state
      ) {
        const { state } = postSignup.agencyProfile;
        const { primaryBusinessLicense, primaryIndividualLicense } =
          postSignup.licenseAndEnO;
        if (
          data.state !== state &&
          (!!primaryBusinessLicense || !!primaryIndividualLicense)
        ) {
          yield put(postSignupActions.clearNpnLicenses());
        }
      }
      if (!!entity && entity === AGENCY_PROFILE && data) {
        const { firstName, lastName } = data;
        let agentName = {
          producer_firstname: firstName,
          producer_lastname: lastName,
        };
        yield put(updateAgentNameInSignUpProcess(agentName));
        yield put(setAuthUserName(agentName.producer_firstname));
        yield put(setAuthUserLastName(agentName.producer_lastname));
        if (!impersonate?.isImpersonate) {
          let isConsentModalOpen = isUndefined(data?.businessCallOptIn);
          if (!isConsentModalOpen) {
            let agencyProfileData = !isEmpty(auth?.agencyProfile)
              ? auth?.agencyProfile
              : {};
            agencyProfileData.businessCallOptIn = true;
            yield put(
              setAuthInfo({
                agencyProfile: agencyProfileData,
              })
            );
          }
          localStorage.setItem(SHOW_CONSENT_MODAL, false);
          yield put(setConsentTextFlag(isConsentModalOpen));
        }
      }

      if (!!signupFlow && signupFlow === SUB_PRODUCER_KEY) {
        var newEntity;
        if (entity === AGENCY_PROFILE) {
          if (!!tax && !!PRODUCTION_ACCESS && PRODUCTION_ACCESS.read) {
            yield put(postSignupActions.updateStepData(data, entity));
            newEntity = TAX_KEY;
            if (
              newEntity === TAX_KEY &&
              !isEmpty(get(producerUpdatedData.data, 'licenseInfo', ''))
            ) {
              if (!!postSignup?.licenseAndEnO) {
                let licenseAndEnoData = {
                  ...postSignup.licenseAndEnO,
                  individualNPN: getValidData(
                    producerUpdatedData.data.licenseInfo,
                    'individualNPN'
                  ),
                  otherStateLicenses: getValidData(
                    producerUpdatedData.data.licenseInfo,
                    'otherStateLicenses'
                  ),
                  primaryIndividualLicense: getValidData(
                    producerUpdatedData.data.licenseInfo,
                    'primaryIndividualLicense'
                  ),
                };
                yield put(
                  postSignupActions.updateLicenseData(licenseAndEnoData)
                );
                yield put(
                  postSignupActions.setIndividualNPNVerified(
                    get(
                      producerUpdatedData.data.licenseInfo,
                      'isIndividualNPNVerified',
                      false
                    )
                  )
                );
              }
            }
            yield put(postSignupActions.updateStepData(tax, newEntity));
          } else {
            yield put(postSignupActions.updateStepData(data, entity));
          }
        } else if (entity === LICENSE_AND_ENO) {
          yield put(postSignupActions.updateStepData(data, entity));
          newEntity = BANK_INFORMATION_KEY;
          yield put(postSignupActions.updateStepData(data, newEntity));
        }
      } else if (!!signupFlow && signupFlow === ALTERNATE_SUB_PRODUCER) {
        if (!!entity && entity === AGENCY_PROFILE) {
          yield put(postSignupActions.updateStepData(data, entity));
        } else if (!!PRODUCTION_ACCESS && PRODUCTION_ACCESS.read) {
          if (entity === TAX_KEY) {
            if (!isEmpty(get(producerUpdatedData.data, 'licenseInfo', ''))) {
              if (!!postSignup?.licenseAndEnO) {
                let licenseAndEnoData = {
                  ...postSignup.licenseAndEnO,
                  businessNPN: data.FEINNotPresent
                    ? ''
                    : getValidData(
                        producerUpdatedData.data.licenseInfo,
                        'businessNPN'
                      ),
                  individualNPN: getValidData(
                    producerUpdatedData.data.licenseInfo,
                    'individualNPN'
                  ),
                  otherStateLicenses: getValidData(
                    producerUpdatedData.data.licenseInfo,
                    'otherStateLicenses'
                  ),
                  primaryIndividualLicense: getValidData(
                    producerUpdatedData.data.licenseInfo,
                    'primaryIndividualLicense'
                  ),
                  primaryBusinessLicense: data.FEINNotPresent
                    ? ''
                    : getValidData(
                        producerUpdatedData.data.licenseInfo,
                        'primaryBusinessLicense'
                      ),
                  businessFileName: data.FEINNotPresent
                    ? ''
                    : postSignup.licenseAndEnO.businessFileName,
                  businessLicenseNumber: data.FEINNotPresent
                    ? ''
                    : postSignup.licenseAndEnO.businessLicenseNumber,
                  businessLicenseUrl: data.FEINNotPresent
                    ? ''
                    : postSignup.licenseAndEnO.businessLicenseUrl,
                  businessFile: data.FEINNotPresent
                    ? null
                    : postSignup.licenseAndEnO.businessFile,
                };
                yield put(
                  postSignupActions.updateLicenseData(licenseAndEnoData)
                );
                yield put(
                  postSignupActions.setIndividualNPNVerified(
                    get(
                      producerUpdatedData.data.licenseInfo,
                      'isIndividualNPNVerified',
                      false
                    )
                  )
                );
                yield put(
                  postSignupActions.setBusinessNPNVerified(
                    get(
                      producerUpdatedData.data.licenseInfo,
                      'isBusinessNPNVerified',
                      false
                    )
                  )
                );
              }
            }
            yield put(postSignupActions.updateStepData(data, entity));
          } else if (entity === LICENSE_AND_ENO) {
            yield put(postSignupActions.updateStepData(data, entity));
          } else {
            yield put(postSignupActions.updateStepData(data, entity));
          }
        } else {
          if (entity === TAX_KEY) {
            yield put(postSignupActions.updateStepData(data, entity));
          } else {
            yield put(postSignupActions.updateStepData(data, entity));
          }
        }
      } else {
        if (
          entity === TAX_KEY &&
          !isEmpty(get(producerUpdatedData.data, 'licenseInfo', ''))
        ) {
          if (!!postSignup?.licenseAndEnO) {
            let licenseAndEnoData = {
              ...postSignup.licenseAndEnO,
              businessNPN: data.FEINNotPresent
                ? ''
                : getValidData(
                    producerUpdatedData.data.licenseInfo,
                    'businessNPN'
                  ),
              individualNPN: getValidData(
                producerUpdatedData.data.licenseInfo,
                'individualNPN'
              ),
              otherStateLicenses: getValidData(
                producerUpdatedData.data.licenseInfo,
                'otherStateLicenses'
              ),
              primaryIndividualLicense: getValidData(
                producerUpdatedData.data.licenseInfo,
                'primaryIndividualLicense'
              ),
              primaryBusinessLicense: data.FEINNotPresent
                ? ''
                : getValidData(
                    producerUpdatedData.data.licenseInfo,
                    'primaryBusinessLicense'
                  ),
              businessFileName: data.FEINNotPresent
                ? ''
                : postSignup.licenseAndEnO.businessFileName,
              businessLicenseNumber: data.FEINNotPresent
                ? ''
                : postSignup.licenseAndEnO.businessLicenseNumber,
              businessLicenseUrl: data.FEINNotPresent
                ? ''
                : postSignup.licenseAndEnO.businessLicenseUrl,
              businessFile: data.FEINNotPresent
                ? null
                : postSignup.licenseAndEnO.businessFile,
            };
            yield put(postSignupActions.updateLicenseData(licenseAndEnoData));
            yield put(
              postSignupActions.setIndividualNPNVerified(
                get(
                  producerUpdatedData.data.licenseInfo,
                  'isIndividualNPNVerified',
                  false
                )
              )
            );
            yield put(
              postSignupActions.setBusinessNPNVerified(
                get(
                  producerUpdatedData.data.licenseInfo,
                  'isBusinessNPNVerified',
                  false
                )
              )
            );
          }
        }
        yield put(postSignupActions.updateStepData(data, entity));
      }
      let showConfirmation = false;
      if (
        !!signupFlow &&
        signupFlow === PRODUCER_KEY &&
        !!entity &&
        entity === AGREEMENT_DATA
      ) {
        yield put(postSignupActions.setHasAcceptedAgreement(true));
        yield put(setDashboardView(true));
        localStorage.setItem('isAgreementAccepted', true);
        showConfirmation = true;
      }
      if (final) {
        yield put(setDashboardView(true));
        const state = yield select();
        yield saveState(state);
        localStorage.setItem('dashboard', true);
        yield put(push('/'));
        // We don't trigger step success for the TAX step in here, it should be triggered after document is signed, which is done somewhere else.
      } else if (!showConfirmation && entity !== TAX_KEY) {
        yield put(postSignupActions.stepSubmitSuccess());
      } else if (entity === TAX_KEY) {
        payload.onFinish && payload.onFinish();
      }
      // push event entry for Google Tag Manager
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'registrationComplete' });
      if (
        entity === LICENSE_AND_ENO &&
        !isEmpty(get(producerUpdatedData.data, 'licenseInfo', ''))
      ) {
        let licenseAndEnoData = {
          ...postSignup.licenseAndEnO,
          otherStateLicenses: getValidData(
            producerUpdatedData.data.licenseInfo,
            'otherStateLicenses'
          ),
          primaryIndividualLicense: getValidData(
            producerUpdatedData.data.licenseInfo,
            'primaryIndividualLicense'
          ),
          primaryBusinessLicense: data.FEINNotPresent
            ? ''
            : getValidData(
                producerUpdatedData.data.licenseInfo,
                'primaryBusinessLicense'
              ),
        };
        yield put(postSignupActions.setLicenseList(licenseAndEnoData));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
    if (
      error.response?.status === 409 &&
      error.response?.data?.message === PHONE_ALREADY_EXIST
    ) {
      yield put(duplicatePhoneNumber(true));
    }
    yield put(postSignupActions.stepSubmitFail());
  } finally {
    if (yield cancelled()) {
      yield put(postSignupActions.submitLoaderHide());
    }
  }
  yield put(postSignupActions.submitLoaderHide());
}

export function* stepSubmitWatcher() {
  yield takeEvery(postSignupType.STEP_SUBMIT, stepSubmit);
}

export function* updateAgreementData({ payload: { stepId, entity, data } }) {
  // When accepting agreement from dashboard
  try {
    const response = yield axios.post('/api/v1/producer/update-producer', {
      data: { [entity]: { ...data, file: '' }, stepId: stepId },
    });
    yield put(updateCarrierAppointmentRequestCount(0));
    localStorage.setItem(CARRIER_APPOINTMENT_REQ_COUNT, 0);
    yield displaySuccessMessage(response.data.message);
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* updateAgreementDataWatcher() {
  yield takeEvery(postSignupType.UPDATE_AGREEMENT_DATA, updateAgreementData);
}

export function* getNPNData({ payload }) {
  try {
    yield put(postSignupActions.submitLoaderShow());
    yield put(postSignupActions.setNiprLoader(true));
    const updatedNIPR = yield axios.post(
      '/api/v1/producer/update-producer-npn',
      {
        data: {
          type: payload.type,
          NPN: payload.NPN,
        },
      }
    );
    if (
      updatedNIPR &&
      !isEmpty(get(updatedNIPR.data, 'licenseInfo', '')) /* &&
      get(updatedNIPR.data, 'success', false) */
    ) {
      const reduxState = yield select();
      const { postSignup } = reduxState;
      if (!!postSignup?.licenseAndEnO) {
        if (payload.type.toLowerCase() === INDIVIDUAL) {
          let licenseAndEnoData = {
            ...postSignup.licenseAndEnO,
            individualNPN: getValidData(
              updatedNIPR.data.licenseInfo,
              'individualNPN'
            ),
            otherStateLicenses: getValidData(
              updatedNIPR.data.licenseInfo,
              'otherStateLicenses'
            ),
            primaryIndividualLicense: getValidData(
              updatedNIPR.data.licenseInfo,
              'primaryIndividualLicense'
            ),
            writesUnderAgencyLicense: payload.writesUnderAgencyLicense,
          };
          yield put(postSignupActions.updateLicenseData(licenseAndEnoData));
          yield put(
            postSignupActions.setIndividualNPNVerified(
              get(
                updatedNIPR.data.licenseInfo,
                'isIndividualNPNVerified',
                false
              )
            )
          );
          yield put(postSignupActions.individualLicenseError(''));
          window.heap.track(APPLICATION.LICENSE_PAGE_OWNER_NPN_CLICK, {
            [NPN_CALL_SUCCESS]: YES,
          });
        }
        if (payload.type.toLowerCase() === FIRM.toLowerCase()) {
          let licenseAndEnoData = {
            ...postSignup.licenseAndEnO,
            businessNPN: getValidData(
              updatedNIPR.data.licenseInfo,
              'businessNPN'
            ),
            otherStateLicenses: getValidData(
              updatedNIPR.data.licenseInfo,
              'otherStateLicenses'
            ),
            primaryBusinessLicense: getValidData(
              updatedNIPR.data.licenseInfo,
              'primaryBusinessLicense'
            ),
            writesUnderAgencyLicense: payload.writesUnderAgencyLicense,
          };
          yield put(postSignupActions.updateLicenseData(licenseAndEnoData));
          yield put(
            postSignupActions.setBusinessNPNVerified(
              get(updatedNIPR.data.licenseInfo, 'isBusinessNPNVerified', false)
            )
          );
          yield put(postSignupActions.agencyLicenseError(''));
          window.heap.track(APPLICATION.LICENSE_PAGE_AGENCY_NPN_CLICK, {
            [NPN_CALL_SUCCESS]: YES,
          });
        }
      }
    }
    if (updatedNIPR && !get(updatedNIPR.data, 'success', false)) {
      if (payload.type.toLowerCase() === INDIVIDUAL) {
        yield put(
          postSignupActions.individualLicenseError(
            get(updatedNIPR.data, 'message', '')
          )
        );
        window.heap.track(APPLICATION.LICENSE_PAGE_OWNER_NPN_CLICK, {
          [NPN_CALL_SUCCESS]: NO,
        });
      }
      if (payload.type.toLowerCase() === FIRM.toLowerCase()) {
        yield put(
          postSignupActions.agencyLicenseError(
            get(updatedNIPR.data, 'message', '')
          )
        );
        window.heap.track(APPLICATION.LICENSE_PAGE_AGENCY_NPN_CLICK, {
          [NPN_CALL_SUCCESS]: NO,
        });
      }
    }
  } catch (error) {
    if (error.response && !get(error.response.data, 'success', false)) {
      if (payload.type.toLowerCase() === INDIVIDUAL) {
        yield put(
          postSignupActions.individualLicenseError(
            get(error.response.data, 'message', '')
          )
        );
        window.heap.track(APPLICATION.LICENSE_PAGE_OWNER_NPN_CLICK, {
          [NPN_CALL_SUCCESS]: NO,
        });
      }
      if (payload.type.toLowerCase() === FIRM.toLowerCase()) {
        yield put(
          postSignupActions.agencyLicenseError(
            get(error.response.data, 'message', '')
          )
        );
        window.heap.track(APPLICATION.LICENSE_PAGE_AGENCY_NPN_CLICK, {
          [NPN_CALL_SUCCESS]: NO,
        });
      }
    }
    yield put(errorHandler(error));
  }
  yield put(postSignupActions.submitLoaderHide());
  yield put(postSignupActions.setNiprLoader(false));
}
export function* getNPNDataWatcher() {
  yield takeEvery(postSignupType.GET_NPN, getNPNData);
}
export function* fetchLeadStatus() {
  try {
    yield put(postSignupActions.setLeadStatusLoader(true));
    const payload = yield select(
      ({ auth: { producerEmail, crmContactId } }) => ({
        producerEmail,
        crmContactId,
      })
    );
    const response = yield axios.post('/api/v1/producer/lead-status', payload);
    yield put(
      postSignupActions.setLeadStatus(response?.data?.leadStatus || '')
    );
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(postSignupActions.setLeadStatusLoader(false));
}

export function* fetchLeadStatusWatcher() {
  yield takeEvery(postSignupType.FETCH_LEAD_STATUS, fetchLeadStatus);
}

export function* setDeviceTransitionEmail({ payload }) {
  try {
    const data = {
      deviceTransitionEmail: payload.deviceTransitionEmail,
    };
    yield put(postSignupActions.setDeviceTransitionEmailLoader(true));
    const response = yield axios.post(
      '/api/v1/producer/update-signup-device-transition-email',
      data
    );
    if (response.status === 200) {
      const request = {
        page: payload.page,
      };
      const value = yield axios.post(
        '/api/v1/producer/send-signup-device-transition-email',
        request
      );
      localStorage.setItem('deviceTransitionEmail', data.deviceTransitionEmail);
      yield displaySuccessMessage(value.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(postSignupActions.setDeviceTransitionEmailLoader(false));
}

export function* setDeviceTrasitionEmailWatcher() {
  yield takeEvery(
    postSignupType.SET_DEVICE_TRANSITION_EMAIL,
    setDeviceTransitionEmail
  );
}

export function* setDeviceTransitionViewed() {
  try {
    const producerId = localStorage.getItem('producer_id');
    yield axios.post('/api/v1/producer/mark-signup-device-transition-viewed', {
      producerId,
    });
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* setDeviceTransitionViewedWatcher() {
  yield takeEvery(
    postSignupType.SET_DEVICE_TRANSITION_VIEWED,
    setDeviceTransitionViewed
  );
}

function* getRefetchedLicense({ payload }) {
  try {
    yield put(postSignupActions.setRefetchLicenseLoader(true));
    const response = yield axios.post(
      `${config.BFF_URL}/api/v1/producer/refetch-licenses`,
      payload
    );
    if (payload.type === 'INDIVIDUAL_NPN') {
      yield put(
        postSignupActions.setIndividualLicenseList(response?.data?.licenses)
      );
    } else if (payload.type === 'BUSINESS_NPN') {
      yield put(
        postSignupActions.setBusinessLicenseList(response?.data?.licenses)
      );
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(postSignupActions.setRefetchLicenseLoader(false));
}

export function* getRefetchedLicenseWatcher() {
  yield takeEvery(postSignupType.FETCH_LICENSE, getRefetchedLicense);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  /* eslint-disable no-shadow */
  const [, , stepSubmit] = yield all([
    fork(stepDataFetchWatcher),
    fork(stepSubmitWatcher),
    fork(updateAgreementDataWatcher),
    fork(getNPNDataWatcher),
    fork(getAgentWatcher),
    fork(fetchLeadStatusWatcher),
    fork(setDeviceTrasitionEmailWatcher),
    fork(setDeviceTransitionViewedWatcher),
    fork(getRefetchedLicenseWatcher),
  ]);

  // For fetch if no auth header set then get token from storage
  if (!axios.defaults.headers.common['Authorization']) {
    axios.defaults.headers.common['Authorization'] =
      'Bearer ' + localStorage.getItem('Authorization');
  }

  // if sign out effect then cancel the pending submit task(if any)
  let stepSubmitTask = stepSubmit;
  while (yield take(postSignupType.SIGNOUT_USER)) {
    yield cancel(stepSubmitTask);
  }
}
