import * as getOrganizationDetails from '../../constants/action-types';

export const fetchOrganizationData = () => {
  return {
    type: getOrganizationDetails.FETCH_ORGANIZATION_DATA,
  };
};

export const organizationDataLoader = data => {
  return {
    type: getOrganizationDetails.ORGANIZATION_DATA_LOADER,
    payload: data,
  };
};

export const setOrganizationData = data => {
  return {
    type: getOrganizationDetails.SET_ORGANIZATION_DATA,
    payload: data,
  };
};

export const setSignupTransLoginSuccess = data => {
  return {
    type: getOrganizationDetails.SET_SIGNUP_TRANSITION_LOGIN_SUCCESS,
    payload: data,
  };
};

export const setSignupTransGetAgentSuccess = data => {
  return {
    type: getOrganizationDetails.SET_SIGNUP_TRANSITION_GET_AGENT_SUCCESS,
    payload: data,
  };
};
