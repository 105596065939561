import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  sendForgotPasswordLink,
  updatePasswordEmailSend,
} from '../../redux/actions/update-password';
import { setInitUrl } from '../../redux/actions/auth';
import { fetchOrganizationData } from 'redux/actions/organization-details';
import IntlMessages from 'util/intl-messages';
import { globalMessages } from 'util/extra';
import { executeRecaptchaCheck } from 'util/recaptcha';

const styles = () => ({
  loginReturnLink: {
    display: 'block',
    width: '100%',
    paddingTop: '10px',
    textAlign: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginTop: '10px',
  },
});

class ResetPwdLink extends React.Component {
  componentDidMount() {
    const { authUser } = this.props;

    this.props.fetchOrganizationData(); // @todo: is this needed anymore?

    if (authUser) {
      this.props?.setInitUrl('/signin');
      this.props?.history?.push('/signin');
    }
  }

  onRecoverPassword = async (event) => {
    event.preventDefault();
    const { producerEmail } = this.props;

    if (!producerEmail) {
      return;
    }

    try {
      const captchaToken = await executeRecaptchaCheck();
      this.props.sendForgotPasswordLink({ producerEmail, captchaToken });
    } catch (error) {
      console.error(error);
    }
  };

  onForgetPassword = () => {
    window.location = '/forgotpassword';
  };

  render() {
    const { classes, updatePasswordEmailSent } = this.props;
    const passwordUpdate = {
      success: false,
      responseStatus: null,
    };

    return (
      <div className="section reset-pwd-link-wrap d-flex align-items-center no-pad-bot blue-grey lighten-5">
        <div className="center-pos justify-content-center animated slideInUpTiny animation-delay-1">
          <div className="card">
            <div className="padding-30 font-weight-bold">
              {!updatePasswordEmailSent.success ? (
                <div className="text-center">
                  <IntlMessages
                    id={
                      this.props.retryable
                        ? 'label.expired.message'
                        : 'label.invalid.message'
                    }
                  />
                  <Link
                    variant="body2"
                    size="small"
                    className="pl-1 pr-1 clickhere-lbl font-weight-bold"
                    onClick={
                      this.props.retryable
                        ? this.onRecoverPassword
                        : this.onForgetPassword
                    }
                  >
                    <IntlMessages id="label.clickhere" />
                  </Link>
                  <IntlMessages
                    id={
                      this.props.retryable
                        ? 'label.get.new.link.message'
                        : 'label.get.password.message'
                    }
                  />
                  <NavLink
                    to="/signin"
                    onClick={() => {
                      this.props.updatePasswordEmailSend(passwordUpdate);
                    }}
                    className={`link-reset ${classes.loginReturnLink}`}
                  >
                    {globalMessages.returnMessage}{' '}
                  </NavLink>
                </div>
              ) : (
                <div className="row font-weight-bold mx-auto">
                  <div className="text-center">
                    <IntlMessages id="label.reset.mail.send.success" />
                  </div>
                  <NavLink
                    to="/signin"
                    onClick={() => {
                      this.props.updatePasswordEmailSend(passwordUpdate);
                    }}
                    className={`link-reset ${classes.loginReturnLink}`}
                  >
                    {globalMessages.returnMessage}{' '}
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, passwordSettings }) => {
  const { updatingPassword, updatePasswordEmailSent, retryable } =
    passwordSettings;
  const { authUser } = auth;
  return {
    updatingPassword,
    authUser,
    updatePasswordEmailSent,
    retryable,
  };
};

export default connect(mapStateToProps, {
  setInitUrl,
  sendForgotPasswordLink,
  fetchOrganizationData,
  updatePasswordEmailSend,
})(withStyles(styles)(withGoogleReCaptcha(ResetPwdLink)));
