import Auth0 from 'auth0-js';

import config from 'config';

const auth0 = new Auth0.WebAuth({
  domain: config.AUTH0_DOMAIN,
  clientID: config.AUTH0_CLIENT_ID,
  scope: 'openid email',
  responseType: 'token',
  redirectUri: window.location.origin + '/signin',
});

export const parseHash = () => {
  return new Promise((resolve, reject) => {
    const options = {
      __enableIdPInitiatedLogin: true, // required for magic links to work
    };
    auth0.parseHash(options, (err, data) => {
      window.location.hash = '';
      if (err) {
        return reject(err);
      }

      resolve(data);
    });
  });
};

/**
 * @description
 * Issues a login redirect to Auth0.
 * If successful, the user will be redirected back to the /signin route with auth params.
 * Use {@link parseHash} to parse the auth params.
 */
export const issueLoginRedirect = (username, password) => {
  return new Promise((resolve, reject) => {
    auth0.login(
      {
        realm: config.AUTH0_CONNECTION ?? 'Username-Password-Authentication',
        audience: config.AUTH0_AUDIENCE,
        username,
        password,
      },
      (err, result) => {
        if (err) {
          return reject(err);
        }

        resolve(result);
      }
    );
  });
};
