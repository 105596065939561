import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import * as commissionActions from 'constants/action-types';
import { push } from 'connected-react-router';
import {
  carrierCommissionUpdate,
  commissionFilesUploadFailed,
  commissionFilesUploading,
  commissionFilesUploadSuccess,
  commissionFilesUploadSuccessFlag,
  fetchStatementData,
  monthAlreadyProcessedCall,
  setCommissionDetails,
  setCommissionLoader,
  setPublishLoader,
  setReviewDocumentLoader,
  setReviewDocuments,
  setStatementData,
  storeDeletedCarrierCommissionRecord,
  updateMonthDetails,
} from '../actions/commissions';
import {
  adHocPaymentFileUploadSuccess,
  adHocPaymentLoader,
} from 'redux/actions/adhoc-payment';
import { displayInfoMessage, displaySuccessMessage } from 'util/user-feedback';
import { errorHandler } from '../actions/error';
import { uploadFile } from 'util/extra';
import config from 'config';

function* fetchCommissions() {
  try {
    yield put(setCommissionLoader(true));
    const commissionDetails = yield axios.get(
      `api/v1/commission/get-monthly-commission-statements`
    );
    if (commissionDetails.data) {
      yield put(setCommissionDetails(commissionDetails.data.payouts));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCommissionLoader(false));
}

export function* fetchCommissionStatementWatcher() {
  yield takeEvery(
    commissionActions.FETCH_COMMISSION_STATEMENTS,
    fetchCommissions
  );
}

function* commissionFilesUpload({
  payload: {
    files,
    index,
    carrier_id,
    monthly_commission_id,
    component_name,
    adhocPayments_id,
  },
}) {
  try {
    const reduxState = yield select();
    const producer_id = reduxState.auth.producer_id;
    const file = files[0];
    const file_name = file.name;
    yield put(commissionFilesUploading(true));
    let response;
    if (component_name === 'adhocpayment') {
      yield put(adHocPaymentLoader(true));
      const fileURL = `ad-hoc-payment/${adhocPayments_id}/${file_name}`;
      response = yield call(uploadFile, file, fileURL);
    } else {
      const fileURL = `${config.COMMISSIONS_UPLOAD_FOLDER}/${monthly_commission_id}/${file_name}`;
      response = yield call(uploadFile, file, fileURL);
    }
    if (response && response.status === 200) {
      const { file: fileURLData } = response.data;
      const url = fileURLData;
      if (component_name === 'adhocpayment') {
        const uploadResponse = yield axios.post(
          '/api/v1/commission/upload-adhoc-payment-file',
          {
            adhoc_payment_id: adhocPayments_id,
            file_path: url,
          }
        );

        yield put(
          adHocPaymentFileUploadSuccess({
            data: uploadResponse.data,
            index,
          })
        );
        yield put(commissionFilesUploadSuccessFlag());
        yield displaySuccessMessage(uploadResponse.data.message);
      } else {
        const uploadResponse = yield axios.post(
          '/api/v1/commission/payout-file/upload',
          {
            carrier_id: carrier_id,
            producer_id: producer_id,
            monthly_commission_id: monthly_commission_id,
            file_path: url,
          }
        );

        if (uploadResponse) {
          yield put(commissionFilesUploadSuccess(url, index));
          yield put(carrierCommissionUpdate(uploadResponse.data, index));
        }
      }
    } else {
      yield put(errorHandler(response));
      yield put(commissionFilesUploadFailed());
    }
  } catch (error) {
    yield put(commissionFilesUploadFailed());
    yield put(errorHandler(error));
  }
  yield put(adHocPaymentLoader(false));
}

export function* commissionFilesUploadWatcher() {
  yield takeEvery(
    commissionActions.COMMISSION_FILES_UPLOAD,
    commissionFilesUpload
  );
}

function* fetchStatementSummary({ payload }) {
  const {
    id,
    status,
    statementDetails: { month, year },
  } = payload;

  try {
    yield put(setCommissionLoader(true));
    yield put(
      updateMonthDetails({
        selectedMonth: month + ' ' + year,
        monthly_commission_id: id,
        status,
      })
    );
    const statementData = yield axios.post(
      `api/v1/commission/payout-file/payout-files-by-month`,
      {
        monthly_commission_id: id,
      }
    );
    if (statementData.data) {
      yield put(setStatementData(statementData.data.payoutFiles));
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCommissionLoader(false));
}

export function* fetchStatementSummaryWatcher() {
  yield takeEvery(
    commissionActions.FETCH_STATEMENT_DATA,
    fetchStatementSummary
  );
}

function* deleteCommissionRecord({ payload }) {
  try {
    const statementDetails = yield axios.post(
      `/api/v1/commission/payout-file/delete-payout-file`,
      {
        ...payload,
      }
    );
    if (statementDetails.data) {
      // used to update the store while updating server db.
      yield put(storeDeletedCarrierCommissionRecord(payload));
      yield displayInfoMessage(statementDetails.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* deleteCommissionRecordWatcher() {
  yield takeEvery(
    commissionActions.DELETE_CARRIER_COMMISSION_RECORD,
    deleteCommissionRecord
  );
}

// Add monthly statement with '+' button
function* addCommissionMonth() {
  try {
    yield put(setCommissionLoader(true));
    const monthDetails = yield axios.post(
      `/api/v1/commission/add-monthly-commission-statement`,
      {}
    );

    // fetch statement details from initiated month API.
    if (monthDetails.data) {
      const payoutDetails = monthDetails.data.payoutObject;
      try {
        yield put(
          fetchStatementData({
            id: payoutDetails.id,
            status: payoutDetails.status,
            statementDetails: {
              month: payoutDetails.month,
              year: payoutDetails.year,
            },
          })
        );
      } catch (error) {
        yield put(errorHandler(error));
        yield put(setCommissionLoader(false));
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
    yield put(setCommissionLoader(false));
    yield put(push('commissions'));
  }
}

export function* addCommissionMonthWatcher() {
  yield takeEvery(commissionActions.ADD_COMMISSION_MONTH, addCommissionMonth);
}

// On pending carrier list modal 'CONTINUE' click fetch 3 links.
function* fetchCarrierDocuments() {
  try {
    const reduxState = yield select();
    yield put(setReviewDocumentLoader(true));
    yield put(monthAlreadyProcessedCall(false));
    yield put(
      setReviewDocuments({
        commission_report: '',
        prenote: '',
        nacha_file: '',
        failedNachaRecords: '',
      })
    );
    const reviewDocumentLinks = yield axios.post(
      `/api/v1/commission/generate-monthly-commission-statements`,
      {
        monthly_commission_id: reduxState.commissions.monthly_commission_id,
      }
    );
    if (reviewDocumentLinks.data) {
      const { commission_report, prenote, nacha_file, failedNachaRecords } =
        reviewDocumentLinks.data;
      yield put(
        setReviewDocuments({
          commission_report,
          prenote,
          nacha_file,
          failedNachaRecords,
        })
      );
    }
  } catch (error) {
    if (
      error.response?.status === 412 &&
      error.response?.data?.message_type === 'alreadyProcessed'
    ) {
      yield put(monthAlreadyProcessedCall(true));
    }
    yield put(errorHandler(error));
  }
  yield put(setReviewDocumentLoader(false));
}

export function* fetchCarrierDocumentsWatcher() {
  yield takeEvery(
    commissionActions.FETCH_CARRIER_DOCUMENTS,
    fetchCarrierDocuments
  );
}

export function* finalPublish() {
  try {
    const reduxState = yield select();
    yield put(setPublishLoader(true));
    const reviewDocumentLinks = yield axios.post(
      `/api/v1/commission/publish-monthly-commission-statements`,
      {
        monthly_commission_id: reduxState.commissions.monthly_commission_id,
      }
    );
    if (reviewDocumentLinks.data) {
      yield displayInfoMessage(reviewDocumentLinks.data.message);
      yield call(fetchCommissions);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setPublishLoader(false));
}

export function* finalPublishWatcher() {
  yield takeEvery(commissionActions.FINAL_PUBLISH, finalPublish);
}

export default function* rootSaga() {
  yield all([
    fork(fetchCommissionStatementWatcher),
    fork(fetchStatementSummaryWatcher),
    fork(commissionFilesUploadWatcher),
    fork(deleteCommissionRecordWatcher),
    fork(addCommissionMonthWatcher),
    fork(fetchCarrierDocumentsWatcher),
    fork(finalPublishWatcher),
  ]);
}
