import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Link, withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { userSignOut } from '../../redux/actions/auth';
import fcLogo from 'assets/images/fc-logo.svg';

const useStyles = (theme) => ({
  root: {
    zIndex: 10,
    flexGrow: 1,
  },
  signoutButton: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2),
  },
});

class ProcessHeader extends React.Component {
  render() {
    const { classes, authUser, orgDetails } = this.props;
    const { organizationName } = !!orgDetails && orgDetails;
    return (
      <div className={classes.root}>
        <AppBar
          elevation={1}
          position="static"
          className={`process-main-header`}
        >
          <Toolbar
            enterdelay={100}
            leavedelay={200}
            className="app-toolbar"
            disableGutters={false}
          >
            <Link className="logo-lg" to="/" title={organizationName}>
              <img
                className="img-fluid pointer"
                src={fcLogo}
                alt={organizationName}
                title={organizationName}
              />
            </Link>

            {authUser && (
              <React.Fragment>
                <Button
                  className={`signout-blue ${classes.signoutButton}`}
                  color="primary"
                  onClick={() => {
                    this.props.userSignOut();
                  }}
                >
                  Sign Out
                </Button>
              </React.Fragment>
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth, organizationDetails }) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition } =
    settings;
  const { authUser } = auth;
  const { orgDetails } = organizationDetails;
  return {
    authUser,
    drawerType,
    horizontalNavPosition,
    locale,
    navigationStyle,
    orgDetails,
  };
};

export default withRouter(
  connect(mapStateToProps, { userSignOut })(
    withStyles(useStyles)(ProcessHeader)
  )
);
